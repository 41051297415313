<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_new_card" lazy aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" :style="`direction:`+lang.ldir" right :title="lang.add_new_card" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{lang.add_new_card}}</span>
        </div>
        <div @click="hide" id="HideMe" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" :style="`direction:${lang.dir} !important`"> 
            <div v-if="customer.id == 0 || customer.customerid == 0" class="tobbuttom backBlack" :style="`direction:${lang.dir};padding:7px;width:100%;text-align:center;font-weight:bold`">
                <b-button :style="`border-radius:0;background:transparent;color:#000;border:none;font-weight:bolder;`">{{lang.customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-weight: bolder;width:153px;font-size:1.1rem !important;margin-inline-end:5px;" id="cInd" :class="`dispFlex btn btn-small blackBack borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="indiv"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-weight: bolder;width:153px;font-size:1.1rem !important" id="cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="comps"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-weight: bolder;width:153px;font-size:1.1rem !important" id="cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="goves" style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
            </div>

            <!-- <div v-if="customer.id == 0 || customer.customerid == 0" class="tobbuttom backBlack" :style="`direction:${$parent.lang.dir};padding:7px;width:100%;text-align:center;`">
                <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{$parent.lang.customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;margin-inline-end:5px;border:2px solid #fff !important" id="_cInd" :class="`dispFlex btn btn-small redColor borderRad_`+$parent.lang.algin" @click="changeCusType(0)" >{{$parent.lang.individual_client}} <i id="_indiv"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important" id="_cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{$parent.lang.company_clinet}} <i id="_comps"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important" id="_cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+$parent.lang.lalgin" @click="changeCusType(2)" >{{$parent.lang.government_clinet}} <i id="_goves" style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
            </div> -->

            <v-row>
                <v-col cols="12" md="2" sm="12" style="display:none;">
                    <label>{{lang.cardid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.cardid"
                        v-model="invoice.billid" 
                        required
                        readonly
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.checkup_id}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="checkUpId" 
                        @change="getCheckup()"
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.quotation}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="quotationId" 
                        @change="getQuotations()"
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.vat_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.vtype"
                        :options="vattype"
                        :label="lang.vat_type"
                        @change="changeVatType()"
                        style="background:red !important"
                        ></b-form-select>
                        <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.payment_method}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.paytype"
                        :options="invoice.paytypes"
                        :label="lang.payment_method"
                        @change="changePtype()"
                        ></b-form-select>
                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12" v-if="$store.state.caneditinvoicedate">
                    <label>{{lang.received_date}}</label>
                    <div class="flexTay">
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.day" :options="$store.state.daysList"/>
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="sd.year" :options="$store.state.yearList" />
                    </div>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12" v-else>
                    <label>{{lang.received_date}}</label>
                    <div class="flexTay">
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.day" :options="$store.state.daysList"/>
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="sd.year" :options="$store.state.yearList" />
                    </div>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.delivery_date}}</label>
                    <div class="flexTay">
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="ed.day" :options="$store.state.daysList"/>
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="ed.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="ed.year" :options="$store.state.yearList" />
                    </div>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.superVisor}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="superVisor"
                            :options="superVisors"
                            @change="updateCarsCount2()"
                            style="background:rergb(255, 136, 0) !important"
                            ></b-form-select>
                        <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.showCarPlate">
                    <label>{{lang.chose_from_list}} {{lang.carsnumber}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="carsnumbers"
                            :options="carsNums"
                            @change="updateCarsCount()"
                            style="background:rergb(255, 136, 0) !important"
                            ></b-form-select>
                        <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
            </v-row> 
            
            <v-row v-if="customer.cctype == 1 && ($store.state.licenseType.cars || $store.state.licenseType.bill)">
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.showIDNumber">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        id="CusMobile"
                        @change="getAllNameById(0)"
                        >{{ customer.customerid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12" style="display:none">
                    <label>{{lang.id_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.id_number"
                        v-model="customer.id_number"
                        size="10"
                        id="CusMobile"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getAllName(1)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.showIDNumber">
                    <label>{{lang.id_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.id_number"
                        v-model="customer.id_number"
                        size="10"
                        id="CusMobile"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <label>{{lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12" v-if="$store.state.licenseType.showOwnerName">
                    <label>{{lang.owner_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.owner_name"
                        v-model="owner_name"
                        >{{ owner_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.work_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="work_type"
                        :options="workTypes"
                        :label="lang.invoice_type"
                        @change="InvType()"
                        style="background:black !important"
                        ></b-form-select>
                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.card_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.card_type"
                        :options="cardTypes"
                        style="background:black !important"
                        ></b-form-select>
                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12" v-if="invoice.card_type==2">
                    <label>{{lang.old_invoice}}</label>
                    <b-form-input
                        class="selborder"
                        v-model="invoice.old_invoice"
                        style="background:green !important"
                        ></b-form-input>
                </v-col>
            </v-row>
            <v-row v-if="showcamp && customer.cctype == 2">
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.showIDNumber">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        id="CusMobile"
                        @change="getAllNameById(0)"
                        >{{ customer.customerid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getAllName(1)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <!-- <v-col cols="12" md="2" sm="8" xs="12">
                    <label>{{lang.chose_company}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="customer.id"
                            :options="customerLists"
                            @change="selectCustomer()"
                            style="background:red !important"
                            ></b-form-select>
                        <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col> -->
                <v-col cols="12" md="2" sm="12" xs="12" v-if="$store.state.licenseType.showproject">
                    <label>{{lang.projectname}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.projectname"
                        v-model="company.projectname"
                        >{{ company.projectname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                     <label>{{lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_name"
                        v-model="company.fullname"
                        style="background:#fff700 !important;"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_vatid"
                        v-model="company.vatid"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12">
                    <label>{{lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.building_no"
                        v-model="company.building_no"
                        style="background:#d3ffd3 !important"
                        >{{ company.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.crt_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.crt_number"
                        v-model="company.crt_number"
                        style=""
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.street_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.street_name"
                        v-model="company.street_name"
                        style="background:#d3ffd3 !important"
                        >{{ company.street_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12">
                    <label>{{lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_number"
                        v-model="company.branch_number"
                        style="background:#d3ffd3 !important"
                        >{{ company.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12">
                    <label>{{lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.zipcode"
                        v-model="company.zipcode"
                        style="background:#d3ffd3 !important"
                        >{{ company.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.district"
                        v-model="company.district"
                        style="background:#d3ffd3 !important"
                        >{{ company.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12">
                    <label>{{lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.city"
                        v-model="company.city"
                        style="background:#d3ffd3 !important"
                        >{{ company.city }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{lang.comapny_address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.comapny_address"
                        v-model="company.address"
                        style="background:#fff700 !important;"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.work_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="work_type"
                        :options="workTypes"
                        :label="lang.invoice_type"
                        @change="InvType()"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.card_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.card_type"
                        :options="cardTypes"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12" v-if="invoice.card_type==2">
                    <label>{{lang.invoice_number}}</label>
                    <b-form-input
                        class="selborder"
                        v-model="invoice.old_invoice"
                        style="background:green !important"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.po_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.po_number"
                        v-model="company.po_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.po_date}}</label>
                    <div class="flexTay">
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="pd.day" :options="$store.state.daysList"/>
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="pd.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="pd.year" :options="$store.state.yearList" />
                    </div>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="$store.state.licenseType.carInsuranceContract">
                    <label>{{ lang.customer_name }}</label>
                    <b-form-input v-model="customer_name" class="inborder"></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="$store.state.licenseType.carInsuranceContract">
                    <label>{{ lang.customer_afforded }}</label>
                    <b-form-input v-model="customer_afforded" class="inborder"></b-form-input>
                </v-col>
            </v-row>
            <v-row v-if="showGov && customer.cctype == 3">
                <!-- <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        id="CusMobile"
                        @change="getAllName(1)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col> -->
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getAllName(1)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <!-- <v-col cols="12" md="2" sm="8" xs="12">
                    <label>{{lang.chose_entity}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="customer.id"
                            :options="customerLists"
                            @change="selectCustomer()"
                            style="background:red !important"
                            ></b-form-select>
                        <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col> -->
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.entity_name"
                        v-model="company.entity_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_name"
                        v-model="company.branch_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.request_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.request_number"
                        v-model="company.request_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.order_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.order_number"
                        v-model="company.order_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.po_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.po_number"
                        v-model="company.po_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.po_date}}</label>
                    <div class="flexTay">
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="pd.day" :options="$store.state.daysList"/>
                        <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="pd.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="pd.year" :options="$store.state.yearList" />
                    </div>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.work_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="work_type"
                        :options="workTypes"
                        :label="lang.invoice_type"
                        @change="InvType()"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.card_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.card_type"
                        :options="cardTypes"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12" v-if="invoice.card_type==2">
                    <label>{{lang.invoice_number}}</label>
                    <b-form-input
                        class="selborder"
                        v-model="invoice.old_invoice"
                        style="background:green !important"
                        ></b-form-input>
                </v-col>
            </v-row>
            <div  v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                <v-row v-for="(item,index) in cars" :key="index">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="2" sm="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{lang.plate_number}}</label> -->
                                <label>{{lang.plate_number}}</label>
                                <div class="plateNumber">
                                    <b-form-input
                                        v-if="$store.state.licenseType.showSplitPlate"
                                        class="inborder"
                                        :label="lang.plate_number"
                                        v-model="cars[index].c1" 
                                        style="background:#fff700 !important;width:30px;"
                                        @keyup="checkMe(cars[index].c1,'c1',index)"
                                    />
                                    <b-form-input
                                        v-if="$store.state.licenseType.showSplitPlate"
                                        class="inborder"
                                        :label="lang.plate_number"
                                        v-model="cars[index].c2" 
                                        style="background:#fff700 !important;width:30px;border-inline:none !important;"
                                        @keyup="checkMe(cars[index].c2,'c2',index)"
                                    />
                                    <b-form-input
                                        v-if="$store.state.licenseType.showSplitPlate"
                                        class="inborder"
                                        :label="lang.plate_number"
                                        v-model="cars[index].c3" 
                                        style="background:#fff700 !important;width:30px;border-inline-end:none !important;"
                                        @keyup="checkMe(cars[index].c3,'c3',index)"
                                    />
                                    <input size="4"
                                        v-if="$store.state.licenseType.showSplitPlate"
                                        class="inborder"
                                        v-model="cars[index].nums" 
                                        style="background:#fff700 !important;width:70px;"
                                        pattern="[0-9]{4,4}"
                                        @keyup="checkNum(index)"
                                        @change="checkNum(index)"
                                    />
                                </div>
                                
                                <b-form-input
                                    v-if="!$store.state.licenseType.showSplitPlate"
                                    class="inborder"
                                    :label="lang.plate_number"
                                    v-model="cars[index].plate_number" 
                                    @change="getCar(cars[index].plate_number,index)"
                                    style="background:#fff700 !important;"
                                />
                                
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{$parent.lang.brand_name}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.brand"
                                    v-model="ecars[index].brand"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="4" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{lang.car_model}}</label> -->
                                <label>{{lang.car_model}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="lang.car_model"
                                    v-model="ecars[index].model"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="lang.car_model"
                                    v-model="cars[index].model"
                                    />
                            </v-col>
                            <v-col cols="12" md="1" sm="8" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{lang.car_year}}</label> -->
                                <label>{{lang.car_year}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="lang.car_year"
                                    v-model="cars[index].made_year"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="lang.car_year"
                                    v-model="cars[index].made_year"
                                    />
                            </v-col>
                            <v-col cols="12" md="1" sm="8" xs="12">
                                <label>{{lang.car_color}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.car_color"
                                    v-model="cars[index].color"
                                    >{{ car.color }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{lang.vin_number}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.vin"
                                    v-model="cars[index].vin"
                                    @input="toUpper(index)"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{lang.engine_meter}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.engine_meter"
                                    v-model="cars[index].engine_meter"
                                    ></b-form-input>
                            </v-col>
                            <!-- 
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{lang.next_oil_change}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.next_oil_change"
                                    v-model="cars[index].next_oil_change"
                                    ></b-form-input>
                            </v-col> -->
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="2" v-if="index!=0">
                        <v-btn class="addCar" style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: red !important;color:#fff !important;width:100%" @click="deleteCar(index)">{{ lang.delete }} <i :class="`fas fa-times`"></i></v-btn>
                    </v-col> -->
                    <!-- <v-col cols="2" v-else>
                        <v-btn class="addCar" style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: green !important;color:#fff !important;width:100%;" @click="addNewCar()">{{ lang.add_car }} <i :class="`fas fa-arrow-${lang.lalgin}`"></i></v-btn>
                    </v-col> -->
                </v-row>
                <!-- <div>
                    <v-btn class="boDry" style="color:#fff;border: none;box-shadow: none;
                    background: green;margin-top:3px;font-size:.7rem;" @click="addNewCar()">{{ lang.add_car }}</v-btn>
                </div> -->
            </div>
            
            <v-row class="mt-5">
                <!-- <v-window>{{lang.invoice_details}}</v-window> -->
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center backBlack" style="width:140px;" v-if="$store.state.licenseType.showTechs">{{lang.worker_name}}</th>
                                <th class="text-center backBlack" style="width:120px;" v-if="$store.state.licenseType.showCarPlate">{{lang.chose_car}}</th>
                                <!-- <th class="backBlack" style="width:100px;" v-if="$store.state.licenseType.showItemCode">{{lang.item_code}}</th> -->
                                <th class="text-center backBlack">{{lang.ment_description}}</th>
                                <th class="text-center backBlack" style="width:50px">{{lang.qtty}}</th>
                                <th class="text-center backBlack" style="width:100px">{{lang.item_price}}</th>
                                <th class="text-center backGreen" style="width:100px">{{lang.total_without_vat}}</th>
                                <th class="text-center backRed" style="width:50px">{{lang.vat}}</th>
                                <th class="text-center backBlack" style="width:100px">{{lang.ftotal}}</th>
                                <th class="text-center backBlack" style="width:100px">{{lang.delete}}</th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td v-if="$store.state.licenseType.showTechs">{{ item.empname }}</td>
                                <td v-if="$store.state.licenseType.showCarPlate">{{ item.plate_number }}</td>
                                <!-- <td v-if="$store.state.licenseType.showItemCode">{{ item.itemcode }}</td> -->
                                <td><b-form-input v-model="tbrows[index].item_name" /></td>
                                <td><b-form-input v-model="tbrows[index].qty" @change="changeVatType()" /></td>
                                <td><b-form-input v-model="tbrows[index].item_price" @change="changeVatType()" /></td>
                                <td class="text-center" style="background:lightgreen !important">{{ item.total }}</td>
                                <td class="text-center" style="background:lightgreen !important">{{ item.vat }}</td>
                                <td class="text-center">{{ item.ftotal }}</td>
                                <td style="">
                                    <b-button type="button" variant="danger" style="padding:0.15rem 0.5rem !important;font-weight:bolder;width:80px;background:red;border:none" @click="removeItem(index)">حذف</b-button>
                                </td>
                            </tr>
                            <tr>
                                <td v-if="$store.state.licenseType.showTechs">
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:#eee !important;color:#000 !important"
                                        :options="workersList"
                                        multiple
                                        v-model="newrow.workers_name">
                                    </b-form-select>
                                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td v-if="$store.state.licenseType.showCarPlate">
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:red !important"
                                        :options="currentCars"
                                        v-model="newrow.plate_number">
                                    </b-form-select>
                                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <!-- <td v-if="$store.state.licenseType.showItemCode">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.itemcode"
                                        @change="getProducts()"
                                        >
                                    </b-form-input>
                                </td> -->
                                <td> 
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.item_name"
                                        :items="itemsworkdes"
                                        @change="calcKit()"
                                        small-chips
                                        
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        style="width:50px;"
                                        v-model="newrow.qty"
                                        @change="calcKit()"
                                        id="newitemqty"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        style="width:75px;"
                                        v-model="newrow.item_price"
                                        @change="calcKit()"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.total"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        style="width:50px;"
                                        v-model="newrow.vat"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.ftotal"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td class="text-center" style="width:85px;">
                                    <b-button type="button" class="btn-sm" variant="success" style="display:none;width:80px;font-weight:bolder;background:green !important;border:none;" @click="addNewRow()">{{lang.add}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <td class="text-center backRed"> {{lang.total_discount}}</td>
                                <td class="text-center backRed">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                    ></b-form-input>
                                </td> -->
                                <!-- <td class="text-center backEmpty"></td> -->
                                <!-- <td class="text-center backEmpty"></td>
                                <th class="text-center backEmpty"></th>
                            </tr> -->
                            <tr>
                                <td v-if="$store.state.licenseType.showTechs"></td>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showCarPlate"></th>
                                <!-- <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th> -->
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backBlack">{{lang.total}}</th>
                                <th class="text-center backGreen">{{ full_total }}</th>
                                <th class="text-center backRed">{{ full_vat }}</th>
                                <th class="text-center backBlack">{{ full_ftotal }}</th>
                                <th class="text-center backEmpty"></th>
                            </tr>
                            <!-- <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> تأمين</th>
                                <th class="text-center">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.insurance"
                                        @change="calcAll()"
                                        type="number"
                                    ></b-form-input>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">الاجمالي بعد التامين</th>
                                <th class="text-center">{{ full_total_in }}</th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr> -->
                            <tr v-if="full_total != 0">
                                <td class="backEmpty" v-if="$store.state.licenseType.showCarPlate"></td>
                                <!-- <td class="backEmpty"></td> -->
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty" colspan="5" style="color:#000 !important;">
                                    <label>{{lang.payment_amount}}</label>
                                    <b-form-input
                                        class="inborder"
                                        :label="lang.payment_amount"
                                        v-model="invoice.payment.cash"
                                        style="background:#c3d9ff;width:150px"
                                        ></b-form-input>
                                </td>
                            </tr>
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>
            <v-row>
                <!-- <v-col cols="12" md="6" sm="12" xs="12">
                    <CVAdditionalRowNew ref="mywork" :getData="getClicked" :lang="$parent.lang" />
                </v-col> -->
                <!-- <v-col cols="12" md="6" sm="12" xs="12">
                    <label>{{lang.add_invoice_notes}}</label>
                    <b-form-textarea
                        class="inborder"
                        :label="lang.add_invoice_notes"
                        v-model="invoice.notes"
                        ></b-form-textarea>
                </v-col> -->
                <v-col class="text-center" cols="12" md="5" sm="12" xs="12">
                    <div class="flexMe">
                        <div>
                            <div style="width:400px;margin:auto">
                                <b-input-group>
                                    <b-form-select class="selborder" :options="AcurrentCars" v-model="selectedCar" />
                                    <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                </b-input-group>
                            </div>
                            <addCarScetch ref="firstCar" :CarsList="AcurrentCars" :carFirst="cars[0].plate_number" />
                        </div>
                        <div class="text-center buttonFlex" style="width:100px;margin:auto">
                            <div style="width:100px;white-space: nowrap;padding-inline:5px;">اختر الاجراء </div>
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:blue !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.reset()">{{lang.reset}}</v-btn>
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:red !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.undo()">{{lang.undo}}</v-btn>
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:green !important;border:1px solid #ccc !important;" @click="save()">{{lang.save}}</v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col class="text-center" cols="12" md="7" sm="12" xs="12">
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th style="padding:2px 2px 8px 2px !important;" colspan="1" class=" text-center">{{ lang.add_attachement }}</th>
                                <th style="padding:2px !important;" colspan="2" class=" text-center">
                                    <input
                                          type="file"
                                          multiple
                                          label="lang.choose_file"
                                          style="background:#ccc;width:50%;border:1px solid #ccc;padding:5px;color:red"
                                          ref="attached"
                                          class="inborder"
                                          @change="selectFiles()"
                                          >
                                        PDF - Docx - xlsx - jpg - png
                                </th>
                            </tr>
                            <tr>
                                <th style="padding:2px !important;width:120px;" class="backBlack">
                                    {{ lang.file_size }} 
                                </th>
                                <th style="padding:2px !important;" class="backBlack">{{ lang.file_name }}</th>
                                <th style="padding:2px !important;width:75px;" class="backBlack">{{ lang.action }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(attach,index) in attached" :key="index">
                                <td>{{ $RoundNums(parseFloat(parseFloat(attach.size)/1024)/1024,2) }} MB</td>
                                <td>{{ attach.name }}</td>
                                <td><i @click.prevent="attached.splice(index,1)" class="fas fa-trash-alt m-2" style="float:left;"></i></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            
                        </tfoot>
                    </b-table-simple>
                </v-col>
                <v-col class="text-center" cols="12" md="5" sm="12" xs="12">
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th style="padding:2px !important;" colspan="3" class=" text-center">{{ lang.cars_status }}</th>
                            </tr>
                            <tr>
                                <th style="padding:2px !important;width:120px;" class="backBlack">
                                    {{ lang.plate }}
                                </th>
                                <th style="padding:2px !important;" class="backBlack">{{ lang.description }}</th>
                                <th style="padding:2px !important;width:75px;" class="backBlack">{{ lang.action }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in carStatus" :key="index">
                                <td style="padding:2px !important;" class="text-center">{{ item.plate }}</td>
                                <td style="padding:2px !important;"><b-form-input class="inborder" v-model="carStatus[index].description" /></td>
                                <td @click="deleteStatus(index)" style="display:flex;align-items:center;justify-content:center;padding:2px !important;border:2px solid #fff !important;background:red !important;;color:#fff !important;text-align:center;cursor:pointer">{{ lang.delete }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style="padding:2px !important;padding:2px !important;">
                                    <b-input-group>
                                        <b-form-select class="selborder" :options="currentCars" v-model="newStatus.plate" />
                                        <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                                            <i class="fas fa-arrow-down"></i>
                                            </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td style="padding:2px !important;padding:2px !important;">
                                    <b-form-input class="inborder" v-model="newStatus.description" />
                                </td>
                                <td @click="addNewStatus()" style="border:2px solid #fff !important;background:green !important;;color:#fff !important;text-align:center;cursor:pointer">{{ lang.add }}</td>
                            </tr>
                        </tfoot>
                    </b-table-simple>
                </v-col>
                <v-col class="text-center" cols="12" md="7" sm="12" xs="12">
                    <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack">{{ lang.terms }}</th>
                                    <th class="backBlack" style="width:80px;">الاجراء</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in terms" :key="index">
                                    <td>{{ item }}</td>
                                    <td>
                                        <v-btn @click="delTerms(index)" style="background:red;color:#fff;padding:3px !important;margin-top:3px;">{{ lang.delete }}</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <b-form-input class="inborder" v-model="iterms" />
                                    </th>
                                    <th>
                                        <v-btn @click="addTerms()" style="background:green;color:#fff;padding:3px !important;margin-top:3px;">{{ lang.add }}</v-btn>
                                    </th>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                </v-col>
                
            </v-row>
            <div class="flexDis" style="margin-top:15px;">
                <div style="border:1px solid #000;width:33%;margin-inline-end:3px;" v-for="(item,index) in images" :key="index">
                    <div>
                        <img style="width:100%;" :src="item.image" />
                    </div>
                    <div style="border:1px solid #000;background:#000;color:#fff" class="text-center">{{ item.plate }}</div>
                </div>
            </div>
        </div>
        <acrdAddApprove ref="aapproveInvoice" />
    </template>
    
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-5">
                <!-- <b-button v-b-toggle.cardApprove id="showApprove" style="display:none">click</b-button> -->
                <b-button v-b-modal.cardApprove id="showApprove" style="display:none">click</b-button>
            <b-button type="button" @click="addInvoicex()" id="addBTN" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{lang.save_n_print}}</b-button>
            </div>
      </template>
    </b-sidebar>
        
        </v-form>
      </div>
</template>

<script>
import axios from 'axios'
import acrdAddApprove from '@/components/acrdAddApproveModal.vue'
import {SnotifyPosition} from 'vue-snotify';
// import CVAdditionalRowNew from '@/components/CVAdditionalRowNew'
import addCarScetch from '@/components/addCarScetch.vue'
import convertNumber from '@/store/convertNumber.js'

export default{
    // components: {invoiceAddApprove,CVAdditionalRowNew,addCarScetch},
    components: {acrdAddApprove,addCarScetch},
    props:[
        'custom','prods','cardid'
    ],
    data() {
        return {
            quotationId: '',
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            pd: {
                day: 0,
                month: 0,
                year: 0,
            },
            checkUpId: 0,
            contract_id: 0,
            customer_name: '',
            customer_afforded: '',
            allUsers: [],
            allProducts: [],
            spareparts: [],
            work_type: 1,
            owner_name: "",
            carsnumbers: 1,
            showClientTypeRow: true,
            showApprove: false,
            carStatus: [],
            newStatus: {
                plate: 0,
                description: ''
            },
            selectedCar: 0,
            images: [],
            clicked: '',
            payby: 1,
            itemsworkdes:[
                
            ],
            car: {
                c1: '',
                c2: '',
                c3: '',
                nums: '',
                plate_number: '',
                color: '',
                model: '',
                plate: {
                    fd: '',
                    sd: '',
                    td: '',
                    num: ''
                },
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
            },
            cars: [{
                c1: '',
                c2: '',
                c3: '',
                nums: '',
                plate: {
                    fd: '',
                    sd: '',
                    td: '',
                    num: ''
                },
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
                brand: ''
            }],
            date: '',
            received_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            delivery_date: '',
            modal: false,
            modal1: false,
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            showcamp: false,
            showGov: false,
            company:{
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
                order_number: '',
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                id_number: 1,
                
            },
            invoice:{
                old_invoice: 0,
                received_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: '',
                    span: ''
                },
                delivery_date: '',
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                next_oil_change: '',
                
                invtype:1,
                
                paytype: 2,
                paytypes: [
                    
                ],
                userid: 0,
                users: [

                ],
                notes: '',
                insurance: 0,
                card_type: 1,
            },
            tbrows:[
            ],
            newrow:{
                empname: '',
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: '',
                workers_name: [],
                brand: ''
            },
            workersList: [],
            customersList: [],
            attached: [],
            terms: [],
            iterms: '',
            calenderTypes: [],
            superVisor: 0
        }
    },
    created() {
        this.getInvSettings();
        // setTimeout(() => {
        //     this.getInfo(); 
        // }, 2000);
        this.getPyamentType();
        // this.invoice.userid
        // this.invoice.userid = this.userinfo.userid

        
        this.changeAdduserInfo();
        this.getUsers();
        this.getWorkType();
        this.getUserList();
        
    },
    computed:{
        workTypes: function(){
            let t = [];

            for(let i=0;i<this.calenderTypes.length;i++){
                t.push({
                    text: this.calenderTypes[i][`name_${this.$parent.lang.langname}`],value: this.calenderTypes[i].value
                })
            }
            return t;
        },
        carsNums: () => {
            return [
                {text: '1',value: 1},
                {text: '2',value: 2},
                {text: '3',value: 3},
                {text: '4',value: 4},
                {text: '5',value: 5},
                {text: '6',value: 6},
            ]
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        cardTypes: function () {
            return [
                {text: this.lang.new_sales,value: 1},
                {text: this.lang.return_sales,value: 2},
            ]
        },
        vattype: function(){
            if(this.$store.state.settings.vat_number == ''){
                return [
                    {
                        text: this.lang.prices_without_vat,
                        value: 1,
                    },
                ]
            }else{
                return [
                    {
                        text: this.lang.prices_include_vat,
                        value: 3,
                    },
                    {
                        text: this.lang.prices_exclusive_vat,
                        value: 2,
                    },
                    {
                        text: this.lang.prices_without_vat,
                        value: 1,
                    },
                ]
            }
            
        },
        invtypes: function(){
            return [
                    {
                        text: this.lang.none_paid_invoice,
                        value: 2,
                    },
                    {
                        text: this.lang.paid_invoice,
                        value: 1,
                    },
                ]
        },
        full_total_in: function(){
            let t = 0;
            t = parseFloat(this.full_ftotal) - parseFloat(this.invoice.insurance);

            return this.$RoundNum(t);
        },
        currentCars: function(){
            let t = [{text:this.lang.chose_car,value:0}];
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number != '')
                {
                    t.push({
                        text: this.cars[i].plate_number,
                        value: this.cars[i].plate_number,
                    })
                }
            }
            return t;
        },
        AcurrentCars: function(){
            let t = [{text: this.lang.chose_car, value: 0}]
            // let t = []
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number != '')
                {
                    t.push({
                        text: this.cars[i].plate_number,
                        value: this.cars[i].plate_number,
                    })
                }
            }
            return t;
        },
        ecars: function(){
            let t = []
            for(let i=0;i<this.cars.length;i++){
                t.push(this.cars[i]);
            }
            return t;
        },
        customerLists: function(){
            let t = [{value: 0,text: this.lang.new_customer}];
            for(let i=0;i<this.customersList.length;i++){
                let customername = this.customersList[i].full_name;
                if(this.customer.cctype == 2){
                    customername = this.customersList[i].company_name;
                }
                else if(this.customer.cctype == 3){
                    customername = this.customersList[i].entity_name;
                }
                t.push({
                    text: customername, value: this.customersList[i].id
                })
            }
            return t;
        },
        superVisors: function() {
            let t=[{text: this.lang.chose_from_list + ' ' + this.lang.superVisor, value: 0}];
            for(let i =0;i<this.allUsers.length;i++){
                if(this.allUsers[i].group_id == 6 && this.$store.state.group_id != 6){
                    t.push({
                        text: this.allUsers[i].full_name,
                        value: this.allUsers[i].id
                    })
                }
                else if(this.allUsers[i].group_id == 6 && this.superVisor == this.allUsers[i].id){
                    t.push({
                        text: this.allUsers[i].full_name,
                        value: this.allUsers[i].id
                    })
                }
            }
            // console.log(t);
            // // console.log("settings",this.$store.state.settings)
            return t;
        }
    },
    methods: {
        getQuotations() {
            
            const post = new FormData();
            post.append('type', "getQuotations");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[id]',this.quotationId)
            post.append('data[status]',0)

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data;
                this.customer.mobile = res[0].mobile;
                this.checkUpId = res[0].checkUpId;
                this.getAllName(1);
                this.cars[0].plate_numebr = res[0].cars[0].plate_number;
                this.cars[0].engine_meter = res[0].cars[0].engine_meter
                this.cars[0].vin = res[0].cars[0].vin
                this.cars[0].model = res[0].cars[0].model
                this.cars[0].brand = res[0].cars[0].brand
                this.cars[0].color = res[0].cars[0].color
                this.cars[0].made_year = res[0].cars[0].made_year
                this.cars[0].plate_number = res[0].cars[0].plate_number
                const pl = this.splitPlate(res[0].cars[0].plate_number)
                this.images[0] = {
                    plate: response.data.results.data[0].images_.plate_number,
                    image: response.data.results.data[0].images_.images
                }
                this.company.po_number = response.data.results.data[0].po_number;
                this.cars[0].c1 = pl[0]
                this.cars[0].c2 = pl[1]
                this.cars[0].c3 = pl[2]
                this.cars[0].nums = pl[3]

                this.cars[0].plate.fd = pl[0]
                this.cars[0].plate.sd = pl[1]
                this.cars[0].plate.td = pl[2]
                this.cars[0].plate.num = pl[3]

                if(response.data.results.data[0].rows.length > 0){
                    for(let i=0;i<res[0].rows.length;i++){
                        console.log(res[0].rows[i])
                        this.tbrows.push({
                            empname: '',
                            itemcode: '',
                            item_name: res[0].rows[i].item_name,
                            qty: res[0].rows[i].qty,
                            item_price:  res[0].rows[i].item_price,
                            total: res[0].rows[i].total,
                            vat: res[0].rows[i].vat,
                            ftotal: res[0].rows[i].ftotal,
                            plate_number: res[0].rows[i].plate_number,
                            workers_name: [],
                            brand: ''
                        })
                    }   
                }
                this.calcKit();
                this.calcAll();

            })

        },
        getCheckup(){
            if(this.checkUpId == 0 || this.checkUpId == ''){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getCurrentCheckup");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.checkUpId);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const respo = response.data.results.data;
                if(response.data.results.data.length == 0){
                    return false;
                }
                this.customer.mobile = response.data.results.data[0].mobile;
                this.getAllName(1);
                this.cars[0].plate_numebr = response.data.results.data[0].plate_number;
                
                //this.getCar(response.data.results.data[0].plate_number,0);
                this.cars[0].plate_number = respo[0].plate_number
                this.cars[0].engine_meter = respo[0].engine_meter
                this.cars[0].vin = respo[0].vin
                this.cars[0].model = respo[0].model
                this.cars[0].brand = respo[0].brand
                this.cars[0].color = respo[0].color
                this.cars[0].made_year = respo[0].made_year
                const pl = this.splitPlate(respo[0].plate_number)
                
                this.cars[0].c1 = pl[0]
                this.cars[0].c2 = pl[1]
                this.cars[0].c3 = pl[2]
                this.cars[0].nums = pl[3]

                this.cars[0].plate.fd = pl[0]
                this.cars[0].plate.sd = pl[1]
                this.cars[0].plate.td = pl[2]
                this.cars[0].plate.num = pl[3]

                this.images[0] = {
                    plate: this.cars[0].plate_number,
                    image: response.data.results.data[0].images
                }
                
                if(response.data.results.data[0].maints.length > 0){
                    for(let i=0;i<response.data.results.data[0].maints.length;i++){
                        this.tbrows.push({
                            empname: '',
                            itemcode: '',
                            item_name: response.data.results.data[0].maints[i].description,
                            qty: 1,
                            item_price: 0,
                            total: 0,
                            vat: 0,
                            ftotal: 0,
                            plate_number: response.data.results.data[0].plate_number,
                            workers_name: [],
                            brand: ''
                        })
                    }   
                }
                if(response.data.results.data[0].statuses.length > 0){
                    for(let i=0;i<response.data.results.data[0].statuses.length;i++){
                        this.carStatus.push({
                            description: response.data.results.data[0].statuses[i].description,
                            plate: response.data.results.data[0].plate_number,
                        })
                    }   
                }
                
                this.company.po_number = response.data.results.data[0].po_number;
            })
            
            
        },
        splitPlate(plate)
        {
            return plate.split(' ');
        },
        formatYear(e){
            //String(e).substring(0,4);
        },
        checkMe(ex,input,index){
            ex = convertNumber.toEng(ex)
            this.cars[index][input] = ex;
            if(input != "nums"){
                const pattern = new RegExp("[A-Za-z\u0621-\u064a]{1,1}");
                if(pattern.test(ex)){
                    const d = ex.split("");
                    this.cars[index][input] = d[0];
                }else{
                    this.cars[index][input] = "";
                }
            }
        },
        checkNum(index){
            const ex = convertNumber.toEng(this.cars[index].nums)
            this.cars[index].nums = ex;
            // mystring = mystring.substring(0, 4);
            // let t = this.cars[index].nums.toString()
            // t  = this.cars[index].nums.substring(0,4);   
            const pattern = new RegExp("[0-9]{4,4}");
            let t = "";
            // console.log("length", t.toString().split("").length)
            if(this.cars[index].nums.toString().split("").length > 4){
                let g = this.cars[index].nums.toString().split("")
                for(let i=0;i<4;i++){
                    t = t +""+ g[i];
                }
            }else{
                t = this.cars[index].nums;
            }
            this.cars[index].nums = t
            

            this.cars[index].plate_number = this.cars[index].c1+' '+this.cars[index].c2+' '+this.cars[index].c3+' '+this.cars[index].nums
            const pla = this.cars[index].plate_number.split("")
            if(pla.length == 10){
                this.getCar(this.cars[index].plate_number,0)
            }
        },
        getUserList(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , 'getUsers');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[user]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(this.$store.state.group_id == 6){
                        this.superVisor = res.results.myId
                    }else{
                        this.superVisor = 0;
                    }
                    this.allUsers = res.results.data.results;
                }
            )
        },  
        toUpper(index){
            this.cars[index].vin=this.cars[index].vin.toUpperCase()
        },
        async getWorkType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getStaticOptions')
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[phrase]','calendarTypes')
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            );
            if(response && response.data){
                this.calenderTypes = response.data.results.data
            }
        },
        updateCarsCount2(){
            // console.log(this.superVisor);
        },
        updateCarsCount() {
            const n = parseFloat(this.carsnumbers) - parseFloat(this.cars.length)
           
            if(n > 0) {
                for(let i = parseFloat(this.cars.length) - 1;i< (parseFloat(this.carsnumbers) - parseFloat(1));i++){
                    // this.cars.push(this.car)
                    this.cars.push({
                        c1: this.car.c1,
                        c2: this.car.c2,
                        c3: this.car.c3,
                        plate: {
                            fd: this.car.fd,
                            sd: this.car.sd,
                            td: this.car.td,
                        },
                        nums: this.car.nums,
                        plate_number:  this.car.plate_number,
                        color:  this.car.color,
                        model:  this.car.model,
                        made_year:  this.car.made_year,
                        workdes:  this.car.workdes,
                        vin: this.car.vin,
                        engine_meter: this.car.engine_meter,
                        next_oil_change: this.car.next_oil_change,
                        brand: this.car.brand
                    })
                }
            }else if(n < 0){
                // let d = parseFloat(this.cars.length) - parseFloat(this.invoice.carsnumber);
                // let i = 0;
                let ca = [];
                for(let i=0;i<this.cars.length;i++){
                    // alert([i,this.invoice.carsnumber]);
                    if(i < parseFloat(this.carsnumbers)){
                        ca.push(this.cars[i])
                    }
                }
                this.cars = ca;
                // while (d != 0){
                //     // console.log(d,this.cars[i]);
                //     if(typeof this.cars[i] !== 'undefined'){
                //         if(this.cars[i].plate_number == ''){
                //             alert(i);
                //             this.cars.splice(i,1);
                //             d = d - 1;
                //         }
                //     }else{
                //         d = d - 1;
                //     }
                    
                //     i++;
                // }
                // if(d != 0){
                //     for(let i = this.cars.length - 1 ;i> d;i--){
                //         alert(d);
                //         this.cars.splice(i,1);
                //     }
                // }   
            }
        },
        delTerms(index) {
            // console.log(this.terms);
            this.terms.splice(index, 1);
        },
        addTerms() {
            this.terms.push(this.iterms);
            this.iterms = '';
        },
        selectFiles(){
            const files = this.$refs.attached.files;
            this.attached = [...this.attached, ...files];
            // console.log("attached",files);
        },
        resetValues() {
            this.checkUpId = 0;
            this.quotationId = 0;
            this.carStatus = []
            this.newStatus = {
                plate: 0,
                description: ''
            }
            this.selectedCar = 0
            this.images = []
            this.clicked = ''
            this.payby = 1
            this.car = {
                c1: '',
                c2: '',
                c3: '',
                nums: '',
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
            }
            this.cars = [{
                c1: '',
                c2: '',
                c3: '',
                nums: '',
                plate: {
                    fd: this.car.fd,
                    sd: this.car.sd,
                    td: this.car.td,
                },
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
            }]
            this.date = ''
            this.received_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.delivery_date = ''
            this.modal = false
            this.modal1 = false
            this.full_total = 0
            this.full_vat = 0
            this.full_ftotal = 0
            this.showcamp = false
            this.showGov = false
            this.company = {
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
                order_number: '',
            }
            this.customer = {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1
            }
            this.invoice = {
                old_invoice: 0,
                received_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: '',
                    span: ''
                },
                delivery_date: '',
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                next_oil_change: '',
                
                invtype:1,
                
                paytype: 2,
                paytypes: [
                    
                ],
                userid: 0,
                users: [

                ],
                notes: '',
                insurance: 0,
                card_type: 1,
            }
            this.tbrows =[]
            this.newrow = {
                empname: '',
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: '',
                workers_name: [],
                brand: ''
            }
            this.attached = [];
            this.customersList = []
            this.getPyamentType();
            this.getUsers();
            // this.$refs.firstCar.image = ''
        },
        selectCustomer(){
            for(let i=0;i<this.customersList.length;i++){
                if(this.customer.cctype == 1 && this.customersList[i].id == this.customer.id){
                    this.customer.customerid = this.customersList[i].id;
                    this.customer.full_name = this.customersList[i].full_name
                    this.company.full_name = ''
                    this.company.entity_name = '';
                    this.company.branch_name = '';

                    this.company.fullname = '';
                    this.company.address = '';
                    this.company.building_no = '';
                    this.company.city = '';
                    this.company.company_vatid = '';
                    this.company.crt_number = '';
                    this.company.district = '';
                    this.company.branch_number = '';
                    this.company.street_name = '';
                    this.company.zipcode = '';
                }
                else if(this.customer.cctype == 2 && this.customersList[i].id == this.customer.id){
                    this.company.customerid = this.customersList[i].id;
                    this.company.fullname = this.customersList[i].company_name;
                    this.company.address = this.customersList[i].address;
                    this.company.building_no = this.customersList[i].building_no;
                    this.company.city = this.customersList[i].city;
                    this.company.company_vatid = this.customersList[i].company_vatid;
                    this.company.crt_number = this.customersList[i].crt_number;
                    this.company.district = this.customersList[i].district;
                    this.company.branch_number = this.customersList[i].branch_number;
                    this.company.street_name = this.customersList[i].street_name;
                    this.company.zipcode = this.customersList[i].zipcode;
                }
                else if(this.customer.cctype == 3 && this.customersList[i].id == this.customer.id){
                    
                    this.company.customerid = this.customersList[i].id;
                    this.company.entity_name = this.customersList[i].entity_name;
                    this.company.branch_name = this.customersList[i].branch_name;

                    this.company.fullname = '';
                    this.company.address = '';
                    this.company.building_no = '';
                    this.company.city = '';
                    this.company.company_vatid = '';
                    this.company.crt_number = '';
                    this.company.district = '';
                    this.company.branch_number = '';
                    this.company.street_name = '';
                    this.company.zipcode = '';
                }
                if(this.customer.id == 0 || this.customersList[i].customer_type != this.customer.cctype){

                    this.customer.customerid = 0;
                    this.customer.id = 0
                    this.company.full_name = ''
                    this.company.entity_name = '';
                    this.company.branch_name = '';

                    this.company.fullname = '';
                    this.company.address = '';
                    this.company.building_no = '';
                    this.company.city = '';
                    this.company.company_vatid = '';
                    this.company.crt_number = '';
                    this.company.district = '';
                    this.company.branch_number = '';
                    this.company.street_name = '';
                    this.company.zipcode = '';
                }
            }
        },
        deleteStatus(item){
            this.carStatus.splice(item,1);
        },
        addNewStatus(){
            if(this.newStatus.plate == '' || this.newStatus.description == '') return false;
            this.carStatus.push({
                plate: this.newStatus.plate,
                description: this.newStatus.description
            });
            this.newStatus.plate = ''
            this.newStatus.description =''
        },
        save(){
            if(this.$refs.firstCar.image == ''){
                let message = this.lang.plase_select_car_scratch;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.selectedCar == '' || this.selectedCar == 0){
                let message = this.lang.chose_car_before_continue;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            let test = false;
            for(let i =0;i < this.images.length;i++){
                if(this.selectedCar == this.images[i].plate){
                    test = true;
                    this.images[i].image = this.$refs.firstCar.image
                }
            }
            if(!test){
                this.images.push({
                    image: this.$refs.firstCar.image,
                    plate: this.selectedCar
                })
            }
            
            this.$refs.firstCar.$refs.VueCanvasDrawing.reset()
            this.$refs.firstCar.image = '';
            this.selectedCar = 0;
        },
        getClicked(clicked){
            this.clicked = clicked
        },
        getUsers(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getWorkers");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {

                this.workersList = response.data.results.data
                this.newrow.workers_name = [response.data.results.data[0].value]
            });
        },
        addInvoicex(){
            this.invoice.received_date = `${this.sd.year}-${('0'+this.sd.month).slice(-2)}-${('0'+this.sd.day).slice(-2)}`;
            this.invoice.delivery_date = `${this.ed.year}-${('0'+this.ed.month).slice(-2)}-${('0'+this.ed.day).slice(-2)}`;
            this.received_date = this.invoice.received_date
            this.delivery_date = this.invoice.delivery_date
            if(this.pd.day != 0 && this.pd.month != 0 && this.pd.year != 0){
                this.company.po_date = `${this.pd.year}-${('0'+this.pd.month).slice(-2)}-${('0'+this.pd.day).slice(-2)}`;
            }
            if(this.customer.mobile == ''){
                let message = this.lang.mobile_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });

                return false;
            }else {
                if(this.$store.state.licenseType.fullMobil){
                    const mob = this.customer.mobile.split("")
                    if(mob[0] != "0" || (mob[1] != 5 && mob[1] != 1) || mob.length != 10){
                        let message = this.lang.mobile_alert_10
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });

                        return false;
                    }
                }
                else{
                    if(this.customer.mobile.length < 4){
                        let message = this.lang.mobile_info_required
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });

                        return false;
                    }
                }
            }
            if(this.customer.id == 0 && this.customer.cctype == 1 && this.customer.full_name == ''){
                let message = this.lang.customer_name_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });

                return false;
            }
            // // console.log("superVisor",this.superVisor);
            if(this.superVisor == '' || this.superVisro == 0){
                let message = this.lang.super_visor_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });

                return false;
            }
            if(this.customer.id == 0 && this.customer.cctype == 2 && this.company.fullname == ''){
                let message = this.lang.company_name_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });

                return false;
            }
            if(this.customer.id == 0 && this.customer.cctype == 2 && this.company.address == ''){
                if(
                    this.company.building_no == '' && this.company.branch_number &&
                    this.company.district == '' && this.company.zipcode &&
                    this.company.street_name == '' && this.company.city 
                ){
                    let message = this.lang.company_address_required;
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });

                    return false;
                }
                this.address = this.company.building_no + ' ' + this.company.street_name + ' ' + this.company.district + ' ' + this.company.city;
                this.address = this.address + this.company.zipcode + '-' + this.company.branch_number 
            }
            if(this.customer.id == 0 && this.customer.cctype == 3 && this.company.entity_name == ''){
                let message = this.lang.entity_name_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });

                return false;
            }
            let cars = false;
            
            for(let i=0;i<this.cars.length;i++){
                if(this.$store.state.licenseType.full_plate_number){
                    if(this.cars[i].c1 == '' || this.cars[i].c2 == '' || this.cars[i].c3 == ''){
                        let message = this.lang.all_calr_plate_field_required;
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });

                        return false;
                    }
                    // // console.log(this.cars[i].nums);
                    const nums = this.cars[i].nums.toString();
                    if(nums.length < 4){
                        let message = this.lang.all_calr_plate_field_required;
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });

                        return false;
                    }
                    this.cars[i].plate_number = this.cars[i].c1 + " " + this.cars[i].c2 + " " + this.cars[i].c3 + " " + this.cars[i].nums
                    if(this.cars[i].plate_number != '' && (this.cars[i].model == '' || this.cars[i].made_year == '' || this.cars[i].color == '' || this.cars[i].brand == '')){
                        let message = this.lang.car_information_must_be_complated.replace('{{plate_number}}',this.cars[i].plate_number);
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });

                        return false;
                    }
                    if(this.cars[i].plate_number != '' && this.cars[i].model != '' && this.cars[i].made_year != ''){
                        cars = true;
                    }
                }
                else{
                    if(this.cars[i].c1 == '' && this.cars[i].c2 == '' && this.cars[i].c3 == '' && this.cars[i].nums == ''){
                        let message = this.lang.car_information_must_be_complated
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });

                        return false;
                    }else{
                        if(this.cars[i].c1 != ''){
                            this.cars[i].plate_number = this.cars[i].c1;
                        }
                        if(this.cars[i].c2 != ''){
                            if(this.cars[i].plate_number != '') this.cars[i].plate_number = this.cars[i].plate_number + " ";
                            this.cars[i].plate_number = this.cars[i].c2;
                        }
                        if(this.cars[i].c3 != ''){
                            if(this.cars[i].plate_number != '') this.cars[i].plate_number = this.cars[i].plate_number + " ";
                            this.cars[i].plate_number = this.cars[i].c3;
                        }
                        if(this.cars[i].nums != ''){
                            if(this.cars[i].plate_number != '') this.cars[i].plate_number = this.cars[i].plate_number + " ";
                            this.cars[i].plate_number = this.cars[i].nums;
                        }
                        // this.cars[i].plate_number = this.cars[i].c1 + " " + this.cars[i].c2 + " " + this.cars[i].c3 + " " + this.cars[i].nums
                        if(this.cars[i].plate_number != '' && (this.cars[i].model == '' || this.cars[i].made_year == '' || this.cars[i].color == '' || this.cars[i].brand == '')){
                            let message = this.lang.car_information_must_be_complated.replace('{{plate_number}}',this.cars[i].plate_number);
                            this.$snotify.error(message, this.lang.alert, {
                                timeout: 1000000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                position: SnotifyPosition.centerCenter,
                                buttons: [
                                    {
                                        text: this.lang.close, 
                                        action: (toast) => {
                                            this.$snotify.remove(toast.id); 
                                        } 
                                    },
                                ]
                            });

                            return false;
                        }
                        if(this.cars[i].plate_number != '' && this.cars[i].model != '' && this.cars[i].made_year != ''){
                            cars = true;
                        }
                    }
                }
            }
            if(!cars){
                let message = this.lang.you_must_add_one_car_atleast
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });

                return false;
            }
            if(this.tbrows.length == 0){
                let message = this.lang.you_must_add_atleast_one_work_item
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });

                return false;
            }
            // const cars = [];
            for(let i= 0;i<this.cars.length;i++){
                this.cars[i].plate_number = this.cars[i].c1 + ' ' + this.cars[i].c2 + ' ' + this.cars[i].c3 + ' ' + this.cars[i].nums;
            }
            this.$refs.aapproveInvoice.modifyIt({
                
                contract_id: this.contract_id,
                customer_name: this.customer_name,
                customer_afforded: this.customer_afforded,
                owner_name: this.owner_name,
                work_type: this.work_type,
                carStatus: this.carStatus,
                customersList: this.customersList,
                workersList: this.workersList,
                tbrows: this.tbrows,
                invoice: this.invoice,
                customer: this.customer,
                company: this.company,
                full_ftotal: this.full_ftotal,
                full_vat: this.full_vat,
                full_total: this.full_total,
                delivery_date: this.delivery_date,
                received_date: this.received_date,
                note: this.date,
                cars: this.cars,
                itemsworkdes: this.itemsworkdes,
                payby: this.payby,
                images: this.images,
                attached: this.attached,
                terms: this.terms,
                superVisor: this.superVisor,
                addNewInvoiceID: true,
                checkUpId: this.checkUpId,
                quotationId: this.quotationId
            })
            document.getElementById('showApprove').click();
            this.showApprove = true;
        },
        reset(){
            this.tbrows = [];
            this.cars = [{
                c1: '',
                c2: '',
                c3: '',
                nums: '',
                plate: {
                    fd: this.car.fd,
                    sd: this.car.sd,
                    td: this.car.td,
                },
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
            }]
            this.company = {
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
            }
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = '';

            this.invoice.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.invoice.billid = 1
            this.invoice.discount = ''
            this.invoice.t_discount = ''
            this.invoice.engine_meter = ''
            this.invoice.next_oil_change = ''
            this.invoice.notes = ''
            this.invoice.insurance = ''
            this.invoice.card_ty = 1;
            this.newrow.itemcode = '';
            this.newrow.item_name = '';
            this.newrow.qty = 1;
            this.newrow.newrow = 1;
            this.resetInvoice();

        },
        updateCars(car){
            let cars = this.cars;
            for(let i=0;i<cars.length;i++){
                if(cars[i].plate_numebr == car.plate_numebr){
                    cars[i] = car;
                }
            }
            this.cars = cars;
        },
        postNewRow(){
            for(let i=0;i<this.currentCars.length;i++){
                this.newrow.plate_number=this.newrow[i].plate_number;
            }
        },
        deleteCar(index){
            this.cars.splice(index,1);
        },
        addNewCar(){
            this.cars.push({
                c1: '',
                c2: '',
                c3:'',
                nums: '',
                plate: {
                    fd: this.car.fd,
                    sd: this.car.sd,
                    td: this.car.td,
                },
                plate_number: '',
                color: '',
                model: '',
                brand: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
            });
        },
        changeCusType(id){
            if(id == 0){
                this.customer.cctype = 1;
                this.showcamp = false;
                this.showGov = false;
                document.getElementById('indiv').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('blackBack');
                document.getElementById('cCom').classList.remove('blackBack');
                document.getElementById('cGov').classList.remove('blackBack');
                document.getElementById('cInd').classList.remove('weightColor');
                document.getElementById('cCom').classList.remove('weightColor');
                document.getElementById('cGov').classList.remove('weightColor');

                document.getElementById('cInd').classList.add('blackBack');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customer.cctype = 2;
                this.showcamp = true;
                this.showGov = false;
                document.getElementById('comps').classList.remove('hideme');
                document.getElementById('indiv').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('blackBack');
                document.getElementById('cCom').classList.remove('blackBack');
                document.getElementById('cGov').classList.remove('blackBack');
                document.getElementById('cInd').classList.remove('weightColor');
                document.getElementById('cCom').classList.remove('weightColor');
                document.getElementById('cGov').classList.remove('weightColor');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('blackBack');
                document.getElementById('cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customer.cctype = 3;
                this.showGov = true;
                this.showcamp = false;
                document.getElementById('goves').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('indiv').classList.add('hideme');

                document.getElementById('cInd').classList.remove('blackBack');
                document.getElementById('cCom').classList.remove('blackBack');
                document.getElementById('cGov').classList.remove('blackBack');
                document.getElementById('cInd').classList.remove('weightColor');
                document.getElementById('cCom').classList.remove('weightColor');
                document.getElementById('cGov').classList.remove('weightColor');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('blackBack');

            }
            // this.changeCtype();
        },
        changeAdduserInfo(){
            const cook = this.$cookies.get(this.$store.state.COOKIEPhase).split('||');
            this.invoice.userid = cook[0]
        },
        getProducts() {
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].qty > 0 || !this.$store.state.licenseType.require_itemcode){
                        this.newrow.itemcode = response.data.results.data[0].itemcode;
                        this.newrow.item_name = response.data.results.data[0].name;
                        this.newrow.item_price = response.data.results.data[0].price;
                        this.newrow.qty = 1;
                        this.calcKit();
                        document.getElementById('newitemqty').focus();
                    }else{
                        this.newrow.itemcode = '';
                        this.newrow.description = '';
                        this.newrow.qty = '';
                        this.newrow.price = '';
                        this.newrow.total = '';
                        let message = this.lang.error_no_qty;
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                    }
                    
                }else{
                    this.newrow.itemcode = '';
                    this.newrow.description = '';
                    this.newrow.qty = '';
                    this.newrow.price = '';
                    this.newrow.total = '';
                    let message = this.lang.error_no_qty
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
                this.calcKit();
            })
        },
        getItemname(){
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getItemBycode"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data.results;
                if(res.length != 0){
                    this.newrow.item_name = res[0].name;
                    this.newrow.item_price = res[0].price;
                }
                this.calcKit();
                document.getElementById('newitemqty').focus();
            })
        },
        getInfo(){
            if(this.custom == null) {return false;}
            this.customer.mobile = this.custom.mobile;
            this.company.fullname = this.custom.company;
            this.company.vatid = this.custom.vatid;
            this.getAllName(1);
        },
        resetInvoice(){
            this.payby = 1;
            this.car = {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            };
            this.modal = false;
            this.full_total =  0;
            this.full_vat =  0;
            this.full_ftotal =  0;
            this.showcamp =  false;
            this.company = {
                fullname: '',
                vatid: '',
                address: '',
            };
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = 1;
            this.invoice.payment.cash = '';
            this.invoice.payment.span = '';
            this.invoice.payment.discount = 0;
            this.invoice.payment.vtype = 0;
            this.invoice.payment.t_discount = 0;
            this.invoice.card_type = 1;
            this.invoice.old_invoice = 0;
            this.changeCusType(0)
            this.tbrows = [
            ];
            this.newrow = {
                plate_number: this.newrow.plate_numebr,
                empname: '',
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                workers_name: [],
                brand: ''
            }
        },
        addInvoice(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.mobile = 1234567890;
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = this.lang.individual_client;
            }

            
            let x = 1;
            if(this.invoice.payment.cash > this.full_ftotal){
                let message = this.lang.pay_more_than_price;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                return false;
            }
            if(this.tbrows.length == 0){
                let message = this.lang.you_can_not_add_empty_invoice;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            // building_no: '',
            // street_name: '',
            // branch_number: '',
            // district: '',
            // zipcode: '',
            // city:'',
            // address:'',
            // crt_number: ''
           let address = true;
           if(this.company.address == ''){
                if(
                    this.company.building_no == '' && this.company.street_name == '' &&
                    this.company.branch_number == '' && this.company.district == '' &&
                    this.company.zipcode == '' && this.company.city == ''
                ){
                    address = false;
                }
           }
        //    if(this.customer.cctype == 2 && (this.company.fullname == '' || !address || this.company.crt_number == '')){
           if(this.customer.cctype == 2 && (this.company.fullname == '' || !address)){
               let message = this.lang.company_ifnormation_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }else if(this.customer.cctype == 2 && this.company.fullname != '' && address){
                this.customer.fullname = this.company.fullname;
                this.customer.full_name = this.company.fullname;
           }
           if(this.customer.cctype == 3 && this.company.entity_name == ''){
            let message = this.lang.company_ifnormation_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }else if(this.customer.cctype == 3 && this.company.entity_name != ''){
                this.customer.fullname = this.company.entity_name;
                this.customer.full_name = this.company.entity_name;
           }
           if(this.$store.state.licenseType.carInfoRequired){
                if(this.car.plate_number == '' || this.car.modal == '' || this.car.made_year == ''){
                    let message = this.lang.car_ifnormation_required;
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    // return false;
                }
           }
            document.getElementById('addBTN').disabled = true;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "addInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }

            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.tbrows.length;i++){
                for(const [key, value] of Object.entries(this.tbrows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            for(let i = 0; i < this.cars.length;i++){
                for(const [key, value] of Object.entries(this.cars[i])){
                    post.append('data[cars]['+i+']['+key+']]',value);
                }
            }
            // for(const [key, value] of Object.entries(this.car)){
            //     post.append('data[car]['+key+']]',value);
            // }
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][itemcode]",0);
            post.append("data[inovice][payment][cash]",this.invoice.payment.cash);
            post.append("data[inovice][payment][span]",this.invoice.payment.span);
            post.append("data[inovice][billid]",this.invoice.billid);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][paytype]",this.invoice.paytype);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",this.full_total);
            post.append("data[inovice][full_vat]",this.full_vat);
            post.append("data[inovice][full_ftotal]",this.full_ftotal);
            post.append("data[inovice][t_discount]",this.invoice.t_discount);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][cash]",this.invoice.payment.cash == '' ? 0 : this.invoice.payment.cash);
            post.append("data[inovice][span]",this.invoice.payment.span);
            post.append("data[inovice][notes]",this.invoice.notes);
            post.append("data[inovice][insurance]",this.invoice.insurance);
            post.append("data[inovice][projectname]",this.company.projectname);
            post.append("data[inovice][card_type]",this.inovice.card_type);
            post.append("data[inovice][old_invoice]",this.inovice.old_invoice);
            // post.append("data[inovice][engine_meter]",this.invoice.engine_meter);
            // post.append("data[inovice][next_oil_change]",this.invoice.next_oil_change);
            post.append("data[cardid]",this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => { 

                document.getElementById('addBTN').disabled = false;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                let message = this.lang.invoice_added;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                document.getElementById('HideMe').click();
                this.printPDF(resposne.data.results.data.results.newID);
                this.resetInvoice();
                
                this.$parent.getCurrentCards();
                
            })
        },
        closeIT(){
            document.getElementById('HideMe').click();
        },
        printInvo(id){
            window.open('../api/print.php?invid='+id,'_blank');
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        changePtype(){
            // let tbs = [];
            //  if(this.tbrows.length != 0){
            //     for(let i = 0; i < this.tbrows.length; i++){
            //         let fftotal = parseFloat(this.tbrows[i].item_price) * parseFloat(this.tbrows[i].qty);
            //         fftotal = this.$RoundNum(fftotal);
            //         const vs = this.$calcVat(fftotal,this.invoice.vtype);
            //         const itm = {
            //             item_name: this.tbrows[i].item_name,
            //             qty: this.tbrows[i].qty,
            //             item_price: this.tbrows[i].item_price,
            //             total: vs.tot,
            //             vat: vs.vat,
            //             ftotal: vs.ftot
            //         }
            //         tbs.push(itm);
            //     }
            // }
            // this.tbrows = tbs;
            // this.calcAll();
            // this.calcKit();
        },
        recalcTbrows(){
            for(let i=0;i < this.tbrows.length;i++){
                const vt = this.invoice.vtype;
                const price = +this.tbrows[i].item_price * +this.tbrows[i].qty;
                const vat = this.$calcVat(price,vt);
                this.tbrows[i].total = this.$RoundNum(vat.tot);
                this.tbrows[i].ftotal = this.$RoundNum(vat.ftot);
                this.tbrows[i].vat = this.$RoundNum(vat.vat);
            }
        },
        changeVatType(){
            this.recalcTbrows();
            this.calcKit();
            this.calcAll();
        },
        calcAll(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    this.full_total = parseFloat(this.full_total) + parseFloat(this.tbrows[i].total);
                    this.full_vat = parseFloat(this.full_vat) + parseFloat(this.tbrows[i].vat);
                    this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.tbrows[i].ftotal);
                }
            }
            if(this.invoice.discount > this.full_ftotal/2){
                this.invoice.discount = this.full_ftotal/2;
            }
            if(this.invoice.discount != 0){
                alert(this.invoice.vtype)
                // this.invoice.t_discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.invoice.t_discount = parseFloat(this.invoice.discount);
                this.full_total = parseFloat(this.full_total) - this.invoice.t_discount;
                const vatic = this.$calcVat(this.full_total,2)
                this.full_vat = vatic.vat;
                this.full_ftotal = vatic.ftot;
            }
            this.full_total = this.$RoundNum(this.full_total);
            this.full_vat = this.$RoundNum(this.full_vat);
            this.full_ftotal = this.$RoundNum(this.full_ftotal);
            if(this.invoice.paytype == 1){
                this.invoice.payment.cash = '';
                this.invoice.payment.span = '';
            }else if(this.invoice.paytype == 2){
                this.invoice.payment.cash = '';
                this.invoice.payment.span = '';
            }
            if(this.invoice.invtype == 2){
                this.invoice.payment.cash = '';
                this.invoice.payment.span = '';
            }
        },
        InvType () {
            if(this.invoice.invtype == 1){
                if(this.paytype == 1){
                    this.invoice.payment.cash = '';
                    this.payment.span = 0;
                }else{
                    this.invoice.payment.span = '';
                    this.invoice.payment.cash = '';
                }
            }else{
                this.invoice.payment.span = '';
                this.invoice.payment.cash = '';
            }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            const vt = this.invoice.vtype;
            const price = +this.newrow.item_price * +this.newrow.qty;
            const vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
            this.addNewRow();
        },
        addNewRow() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.item_name == ''){
                // let message = this.lang.can_not_add_item_withoud_discription;
                // this.$snotify.error(message, this.lang.alert, {
                //     timeout: 1000000,
                //     showProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     position: SnotifyPosition.centerCenter,
                //     buttons: [
                //         {
                //             text: this.lang.close, 
                //             action: (toast) => {
                //                 this.$snotify.remove(toast.id); 
                //             } 
                //         },
                //     ]
                // });
                return false;
            }
            if(this.newrow.qty == '' || this.newrow.qty == 0){
                // let message = this.lang.item_qtty_must_be_more_than_zero;
                // this.$snotify.error(message, this.lang.alert, {
                //     timeout: 1000000,
                //     showProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     position: SnotifyPosition.centerCenter,
                //     buttons: [
                //         {
                //             text: this.lang.close, 
                //             action: (toast) => {
                //                 this.$snotify.remove(toast.id); 
                //             } 
                //         },
                //     ]
                // });
                return false;
            }
            if(!this.$store.state.zeroPrice){

                if(this.newrow.item_price == '' || this.newrow.item_price == 0){
                    // let message = this.lang.item_price_must_be_more_than_zero;
                    // this.$snotify.error(message, this.lang.alert, {
                    //     timeout: 1000000,
                    //     showProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     position: SnotifyPosition.centerCenter,
                    //     buttons: [
                    //         {
                    //             text: this.lang.close, 
                    //             action: (toast) => {
                    //                 this.$snotify.remove(toast.id); 
                    //             } 
                    //         },
                    //     ]
                    // });
                    
                    return false;
                }
            }else{
                if(this.newrow.item_price == ''){
                    // let message = this.lang.item_price_must_be_more_than_zero;
                    // this.$snotify.error(message, this.lang.alert, {
                    //     timeout: 1000000,
                    //     showProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     position: SnotifyPosition.centerCenter,
                    //     buttons: [
                    //         {
                    //             text: this.lang.close, 
                    //             action: (toast) => {
                    //                 this.$snotify.remove(toast.id); 
                    //             } 
                    //         },
                    //     ]
                    // });
                    
                    return false;
                }
            }
            this.newrow.empname = "";
            for(let i=0;i<this.workersList.length;i++){
                for(let j=0;j<this.newrow.workers_name.length;j++){
                    if(this.newrow.workers_name[j] == this.workersList[i].value){
                        this.newrow.empname += (this.newrow.empname == '' ? "" : " - ") + this.workersList[i].text;
                    }
                }
            }
            this.tbrows.push(this.newrow);
            this.newrow = {
                empname: '',
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: 0,
                vat: 0,
                ftotal: 0,
                plate_number: this.newrow.plate_number,
                workers_name: this.newrow.workers_name,
                brand: '',
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            if(this.$store.state.settings.vat_number == ''){
                this.invoice.vtype = 1;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allDesc = res.results.data.desk;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.allProducts = res.results.data.products;
                            this.spareparts = [];
                            for(let i= 0; i < res.results.data.products.length; i++){
                                this.spareparts.push(res.results.data.products[i].name);
                            }
                        }
                    }
                }
            )
        },
        getName(){
            this.customer.mobile = '';
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    console.log("customer", res.results.data);
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.cctype = res.results.data['customer_type'];
                        this.customer.customer_type = res.results.data['customer_type'];
                        this.changeCusType(parseInt(res.results.data.customer_type) - parseInt(1))
                        this.customer.mobile = res.results.data['mobile'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.customer.customerid = res.results.data['id'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                        this.company.crt_number = res.results.data['crt_number'];
                        this.company.address = res.results.data['address'];
                        this.company.city = res.results.data['city'];
                        this.company.zipcode = res.results.data['zipcode'];
                        this.company.district = res.results.data['district'];
                        this.company.branch_number = res.results.data['branch_number'];
                        this.company.street_name = res.results.data['street_name'];
                        this.company.building_no = res.results.data['building_no'];
                        if(res.results.data.customer_type == 3){
                            this.company.entity_name = res.results.data['entity_name'];
                        }else{
                            this.company.entity_name = '';
                        }
                        // this.compnay.entity_name = typeof res.results.data !== 'undefined' ?  res.results.data['entity_name'] : "";
                        this.company.entity_branch_number = typeof res.results.data !== 'undefined' ?  res.results.data['entity_branch_number'] : '';
                        if(this.company.address == 'undefined' || this.company.address == ''){
                            this.company.address = this.company.building_no != '' ? this.company.building_no + "," : ''; 
                            this.company.address += this.company.street_name != '' ? this.company.street_name + " " : '';
                            this.company.address += (this.company.branch_number != '' && this.company.zipcode != '') ? this.company.branch_number + " - " + this.company.zipcode + ", " :'';
                            this.company.address += this.company.district + " - "+ this.company.city;
                        }
                    }
                }
            )
        },
        getAllNameById(id){

            this.customer.mobile = "";
            // this.customer.customerid = "";
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfoAll');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile ? this.customer.mobile : '');
            post.append('data[customerid]',this.customer.customerid);
            post.append('data[customer_type]',this.customer.cctype);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.customersList = res.results.data;
                    
                    // if(this.customer.customerid != ''){
                        this.company = {
                            fullname: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].company_name : '',
                            vatid: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].company_vatid : '',
                            projectname: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].projectname : '',
                            building_no: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].building_no : '',
                            street_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].street_name : '',
                            branch_number: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].branch_number : '',
                            district: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].district : '',
                            zipcode: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].zipcode : '',
                            city: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].city : '',
                            address: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].address : '',
                            crt_number: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].crt_number : '',
                            entity_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].entity_name : '',
                            branch_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].branch_name : '',
                            entity_branch_number: '',
                            request_number: "",
                            po_number: "",
                            po_date: "",
                        }
                        this.customer = {
                            id: res.results.data[0].id,
                            customerid: res.results.data[0].id,
                            mobile: res.results.data[0].mobile,
                            full_name: res.results.data[0].full_name,
                            cctype: res.results.data[0].customer_type
                        }
                        this.changeCusType(parseFloat(res.results.data[0].customer_type) - 1)
                    // }
                }
            )
        },
        getAllName(){
            this.customer.mobile = convertNumber.toEng(this.customer.mobile)
            this.customer.customerid = '';
            // if(this.customer.customerid != '') this.customer.mobile = "";
            // if(this.customer.mobile != '') this.customer.customerid = "";
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfoAll');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            post.append('data[customer_type]',this.customer.cctype);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.customersList = res.results.data;
                    
                    // if(this.customer.customerid != ''){
                        this.company = {
                            fullname: res.results.data[0].company_name,
                            vatid: res.results.data[0].company_vatid,
                            projectname: res.results.data[0].projectname,
                            building_no: res.results.data[0].building_no,
                            street_name: res.results.data[0].street_name,
                            branch_number: res.results.data[0].branch_number,
                            district: res.results.data[0].district,
                            zipcode: res.results.data[0].zipcode,
                            city: res.results.data[0].city,
                            address: res.results.data[0].address,
                            crt_number: res.results.data[0].crt_number,
                            entity_name: res.results.data[0].entity_name,
                            branch_name: res.results.data[0].branch_name,
                            entity_branch_number: '',
                            request_number: "",
                            po_number: "",
                            po_date: "",
                        }
                        this.customer = {
                            id: res.results.data[0].id,
                            mobile: res.results.data[0].mobile,
                            full_name: res.results.data[0].full_name,
                            customerid: res.results.data[0].id,
                            cctype: res.results.data[0].customer_type
                        }
                    // }
                    this.changeCusType(parseFloat(res.results.data[0].customer_type) - 1);
                }
            )
        },
        getPyamentType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.invoice.paytypes.push({
                        text: this.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.invoice.paytype = res.results.data[0].id;
            })
        },
        getCar(plate_number,index){
            console.log([plate_number, index, this.cars[index].plate_number])
            if(plate_number == ''){
                plate_number = this.cars[index].plate_number;
            }else{
                this.cars[index].plate_number = plate_number
            }
            
            this.selectedCar = this.cars[index].plate_number;
            this.newStatus.plate = this.cars[index].plate_number;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','carInfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[plate_number]',this.cars[index].plate_number);
            post.append('data[c1]',this.cars[index].c1);
            post.append('data[c2]',this.cars[index].c2);
            post.append('data[c3]',this.cars[index].c3);
            post.append('data[nums]',this.cars[index].nums);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    let cars = [];
                    if(res.error.number == 200){
                        let car = {
                            c1: res.results.data.c1,
                            c2: res.results.data.c2,
                            c3: res.results.data.c3,
                            nums: res.results.data.nums,
                            plate_number:res.results.data.plate_number,
                            made_year:res.results.data.made_year,
                            model:res.results.data.model,
                            vin:res.results.data.vin,
                            id:res.results.data.id,
                            color:res.results.data.color,
                            brand:res.results.data.brand,
                        }
                        this.newrow.plate_number = res.results.data.plate_number;
                        // this.updateCars(car);
                        this.cars[index] = car;
                        
                        this.addNewCar();
                        this.cars.pop()
                    }else{
                        this.newrow.plate_number = plate_number;
                        let car = {
                            c1: res.results.data.c1,
                            c2: res.results.data.c2,
                            c3: res.results.data.c3,
                            nums: res.results.data.nums,
                            plate_number:plate_number,
                            made_year:'',
                            model:'',
                            vin:'',
                            id:'',
                            brand: '',
                            color:'',
                        }
                        this.cars[index] = car;
                        this.addNewCar();
                        this.cars.pop()
                    }
                }
            )
        }
    },
    // watch: {
    //     // whenever question changes, this function will run
    //     cars(oldvalues, newvalues) {
    //         if (newvalues.includes('?')) {
    //             //this.getAnswer()
    //             this.updateCars()

    //         }
    //     }
    // },
}
</script>
<style>
.dispFlex{
    display:flex;
    flex-direction: row;
    justify-content: center !important;
    padding-left:10px;
    padding-right:10px;
    justify-items: center;
    align-items: center;
}
.boDry:hover{
    background:green !important;
}
.addCar{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.tobbuttom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.redColor {
    background: #ccc!important;
    color: #fff!important;
}
.weightColor {
    background: #fff !important;
    color: #000 !important;
}
.borderRad_none, .borderRad_right {
    border-top-left-radius: 0 0 !important;
    border-bottom-left-radius: 0 0 !important;
    padding: 5px 10px !important;
    font-size: .8rem !important;
}
.hideme{
    display:none !important;
}
.borderRad_none {
    border-top-right-radius: 0 0 !important;
    border-bottom-right-radius: 0 0 !important;
}
.borderRad_left {
    border-top-left-radius: 5px 5px !important;
    border-bottom-left-radius: 5px 5px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 5px 10px !important;
    font-size: .8rem !important;
    -webkit-margin-start: 5px !important;
    margin-inline-start: 5px !important;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
    padding: 6px 12px;
}
.flexDis{
    margin:5px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-basis: 100%;
    flex-wrap: wrap;
    width:100%;
}
.flexMe{
    margin:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.buttonFlex{
    margin:auto;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.plateNumber{
    margin:auto;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexTay{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
}
.flexTay select{
    margin-inline-end: 2px;
}
</style>