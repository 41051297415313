<template>
    <b-sidebar id="new_add_invoice_adv" aria-labelledby="sidebar-no-header-title" no-header backdrop width="85%" :style="`direction:`+lang.ldir" right :title="lang.add_direct_invoice" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>البحث المتقدم</span>
                </div>
                <div @click="hide" id="HideAdvInvoice" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row class="mt-5">
                    <v-col cols="12" md="6">
                        <label>البحث برمز الصنف</label>
                        <div class="flexRow">
                            <b-form-input class="inborder inputRow" style="width:150px;" v-model="search.itemcode" />
                            <b-form-select class="selborder inputRow" style="width:300px;" v-model="search.code_type" :options="searchTypes" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <label>البحث بأي جزء من اسم الصنف</label>
                        <b-form-input class="inborder" v-model="search.item_name" /></v-col>
                    <v-col cols="12" class="text-left">
                        <label></label>
                        <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="searchForItem()">بحث</b-button>
                    </v-col>
                </v-row>
                <v-row class="mt-5">
                    <v-col cols="12" md="12">
                        <v-simple-table>
                            <thead>
                                <th class="text-center backBlack">رمز الصنف</th>
                                <th class="text-center backBlack">اسم الصنف</th>
                                <th class="text-center backBlack">الماركة</th>
                                <th class="text-center backBlack">التكلفة</th>
                                <th class="text-center backBlack">السعر الادنى</th>
                                <th class="text-center backBlack">السعر الأعلى</th>
                                <th class="text-center backBlack">سعر البيع</th>
                                <th class="text-center backBlack">آخر فاتورة</th>
                                <th class="text-center backBlack">الكمية المتوفرة</th>
                                <th class="text-center backBlack">الوحدة</th>
                                <th class="text-center backBlack" colspan="2">الاجراء</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in shearchRes" :key="index">
                                    <td class="text-center" style="direction:ltr !important">{{ item.item_code }}</td>
                                    <td class="text-center">{{ item[`item_name${lang.langname}`] }}</td>
                                    <td class="text-center">{{ item[`item_brand${lang.langname}`] }}</td>
                                    <td class="text-center">{{ item.costs }}</td>
                                    <td class="text-center">{{ item.min_price }}</td>
                                    <td class="text-center">{{ item.max_price }}</td>
                                    <td class="text-center">{{ item.default_price }}</td>
                                    <td class="text-center">{{ item.last_invoice }}</td>
                                    <td class="text-center">{{ item.available_qty }}</td>
                                    <td class="text-center">{{ item[`unit_name${lang.langname}`] }}</td>
                                    <td class="text-center backBlue Pointer" @click="addToInvoice(item)">{{ lang.add }}</td>
                                    <td class="text-center backGreen Pointer" >حركة الصنف</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
        </template>
    </b-sidebar>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            search: {
                itemcode: '',
                code_type: 4,
                item_name: '',
            },
            shearchRes: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        searchTypes: function() {
            return [
                {text: 'كامل النص', value: 1},
                {text: 'بداية النص', value: 3},
                {text: 'نهاية النص', value: 2},
                {text: 'اي جزء من النص', value: 4},
            ];
        }
    },
    methods: {
        reset() {
            this.search = {
                itemcode: '',
                code_type: 4,
                item_name: '',
            },
            this.shearchRes = []
        },
        searchForItem()
        {  
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getNProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[search]', JSON.stringify(this.search));
            post.append('data[store_id]', this.$parent.$parent.invoice.store_id)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.shearchRes = response.data.results.data;
            })
        },
        addit(item){
            this.$parent.$parent.tbrows.push({
                plate_number: '',
                itemcode: item.item_code,
                item_name: item[`item_name${this.lang.langname}`],
                extra1: '',
                extra2: '',
                extra3: '',
                unitname: item[`unit_name${this.lang.langname}`],
                item_price: item.default_price,
                qty: 1,
                total: item.default_price,
                vat: 0,
                ftotal: item.default_price
            })
            this.$parent.$parent.changeVatType();
        },
        async addToInvoice(item) {
            let x = 0;
            const length = this.$parent.$parent.tbrows.length;
            for (let i = 0; i < length; i++) {
                if (this.$parent.$parent.tbrows[i].item_name == item.item_name) {
                    const result = await this.showSnotify();
                    if (result === 'cancel') {
                        x = 1;
                        break;
                    }
                }
            }
            if (x == 0) this.addit(item);
        },
        showSnotify()
        {
            return new Promise((resolve) => {
                this.$snotify.error("تم اضافة الصنف الى الفاتورة مسبقا هل ترغب بالمتابعة", this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.agree,
                            action: (toast) => {
                                this.$snotify.remove(toast.id);
                                resolve('agree');
                            }
                        },
                        {
                            text: this.lang.cancel,
                            action: (toast) => {
                                this.$snotify.remove(toast.id);
                                resolve('cancel');
                            }
                        },
                    ]
                });
            });
        }
    }
}
</script>
<style>
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.inputRow{
    margin: 2px;
}
.Pointer{
    cursor:pointer;
}
</style>


