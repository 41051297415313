<template>
    <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
        <v-form
            ref="forinv"
            lazy-validation
            autocomplete="off"
        >
        <b-sidebar id="add_quotation" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" style="direction:ltr" right shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" :style="`direction:rtl`">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.add_quotation}}</span>
        </div>
        <div @click="hide" id="closeDil" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir+``">
            <div v-if="customer.id == 0 && $store.state.licenseType.showCustomerTypes" class="tobbuttom" :style="`direction:${$parent.lang.dir};padding:7px;width:100%;text-align:center;background:#efefef;border:1px solid #bbb;`">
                <b-button :style="`border-radius:0;background:transparent;color:#000;border:none;`">{{$parent.lang.chose_customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cInd" :class="`dispFlex btn btn-small weightColor borderRad_`+$parent.lang.algin" @click="changeCusType(0)" >{{$parent.lang.individual_client}} <i id="indiv"  class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cCom" :class="`dispFlex btn btn-small blackBack borderRad_none`" @click="changeCusType(1)" >{{$parent.lang.company_clinet}} <i id="comps"  class="fas fa-arrow-down "></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+$parent.lang.lalgin" @click="changeCusType(2)" >{{$parent.lang.government_clinet}} <i id="goves" class="fas fa-arrow-down hideme"></i></b-button>
            </div>
            <v-row>
                <v-col cols="12" md="2" sm="12" style="display:none;">
                    <label>{{$parent.lang.quotnumber}}</label>
                    <b-form-input
                        :label="$parent.lang.quotnumber"
                        v-model="invoice.billid" 
                        readonly
                        class="inborder"
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.show_jobcard">
                    <label>{{lang.checkup_id}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="checkUpId" 
                        @change="getCheckup()"
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <label>{{$parent.lang.full_name}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="customer.full_name"
                        required
                        :rules="fieldRules"
                        id="CusMobile"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                
                <v-col cols="12" md="4" sm="12" xs="12"  v-if="showcamp">
                    <label>{{$parent.lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_name"
                        v-model="company.fullname"
                        id="CusMobile"
                        @change="getName()"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12"  v-if="showcamp">
                    <label>{{$parent.lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_vatid"
                        v-model="company.vatid"
                        id="CusMobile"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12"  v-if="showcamp">
                    <label>{{$parent.lang.crt_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.crt_number"
                        v-model="company.crt_number"
                        id="CusMobile"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12"  v-if="showcamp">
                    <label>{{$parent.lang.po_date}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="company.po_date"
                        type="date"
                        id="po_date"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12"  v-if="showcamp">
                    <label>{{$parent.lang.po_number}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="company.po_number"
                        id="po_date"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12"  v-if="showcamp && $store.state.licenseType.carInsuranceContract">
                    <label>{{$parent.lang.afforded_customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="invoice.customer_name"
                        id="po_date"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12" xs="12"  v-if="showcamp && $store.state.licenseType.carInsuranceContract">
                    <label>{{$parent.lang.customer_afforded}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="invoice.customer_afforded"
                        @change="calcAll()"
                        id="po_date"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12" xs="12"  v-if="showcamp && $store.state.licenseType.carInsuranceContract">
                    <label>{{$parent.lang.consumption_fees}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="invoice.consumption_fees"
                        @change="calcAll()"
                        id="po_date"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="1" xs="12" v-if="showcamp && $store.state.licenseType.carInsuranceContract">
                    <label>{{$parent.lang.chose_from_list}} {{$parent.lang.consumption_rate}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.consumption_rate"
                        :options="consumptionRate"
                        style="background:red !important;display:none;"
                        @change="calcAll()"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12" v-if="showGov">
                    <label>{{$parent.lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.entity_name"
                        v-model="company.entity_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12" v-if="showGov">
                    <label>{{$parent.lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_name"
                        v-model="company.branch_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12" v-if="showGov">
                    <label>{{$parent.lang.bid_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.bid_number"
                        v-model="invoice.bid_number"
                        ></b-form-input>
                </v-col>
            </v-row>
            <div  v-if="($store.state.licenseType.cars || $store.state.licenseType.carinfo) && $store.state.licenseType.showCars">
                <v-row v-for="(item,index) in cars" :key="index">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="2" sm="3">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{$parent.lang.plate_number}}</label> -->
                                <label>{{$parent.lang.plate_number}}</label>
                                <div class="plateNumber" v-if="$store.state.licenseType.SeparatePlate">
                                    <b-form-input class="inborder" v-model="cars[index].c1" @change="plNum(index)" style="background:#fff700 !important;width:30px;background:#efefef;" />
                                    <b-form-input class="inborder" v-model="cars[index].c2" @change="plNum(index)" style="border-inline:none !important;background:#fff700 !important;width:30px;background:#efefef;" />
                                    <b-form-input class="inborder" v-model="cars[index].c3" @change="plNum(index)" style="border-inline-end:none !important;background:#fff700 !important;width:30px;background:#efefef;" />
                                    <b-form-input class="inborder" v-model="cars[index].text" @change="plNum(index)" style="background:#fff700 !important;width:60px;background:#efefef;" />
                                </div>
                                <b-form-input
                                    v-else-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="$parent.lang.plate_number"
                                    v-model="cars[index].plate_number" 
                                    @change="getCar(cars[index].plate_number,index)"
                                    style="background:#fff700 !important;"
                                />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="$parent.lang.plate_number"
                                    v-model="cars[index].plate_number" 
                                    @change="getCar(cars[index].plate_number,index)"
                                />
                            </v-col>
                            <v-col cols="12" md="2" sm="3" xs="12">
                                <label>{{$parent.lang.brand}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.brand"
                                    v-model="cars[index].brand"
                                    style=""
                                    />
                            </v-col>
                            <v-col cols="12" md="2" sm="3" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{$parent.lang.car_model}}</label> -->
                                <label>{{$parent.lang.car_model}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="$parent.lang.car_model"
                                    v-model="cars[index].model"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="$parent.lang.car_model"
                                    v-model="cars[index].model"
                                    />
                            </v-col>
                            <v-col cols="12" md="2" sm="3" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{$parent.lang.car_year}}</label> -->
                                <label>{{$parent.lang.car_year}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="$parent.lang.car_year"
                                    v-model="cars[index].made_year"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="$parent.lang.car_year"
                                    v-model="cars[index].made_year"
                                    />
                            </v-col>
                            <v-col cols="12" md="2" sm="3" xs="12">
                                <label>{{$parent.lang.car_color}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.car_color"
                                    v-model="cars[index].color"
                                    >{{ car.color }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="3" xs="12">
                                <label>{{$parent.lang.vin_number}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.vin"
                                    v-model="cars[index].vin"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="3" xs="12">
                                <label>{{$parent.lang.engine_meter}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.engine_meter"
                                    v-model="cars[index].engine_meter"
                                    ></b-form-input>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="1" v-if="index!=0" style="display:none">
                        <v-btn style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: red !important;color:#fff !important;padding: 0 !important;" @click="deleteCar(index)">حذف</v-btn>
                    </v-col>
                    <v-col cols="1" style="display:none" v-if="$store.state.licenseType.showCarPlate">
                        <v-btn style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: blue !important;color:#fff !important;padding: 0 !important;" @click="addNewCar()">{{ $parent.lang.add }}</v-btn>
                    </v-col>
                </v-row>
                <!-- <div>
                    <v-btn class="boDry" style="color:#fff;border: none;box-shadow: none;
                    background: green;margin-top:3px;font-size:.7rem;" @click="addNewCar()">{{ $parent.lang.add_car }}</v-btn>
                </div> -->
            </div>
            <v-row class="mt-2">
                <v-col cols="12" md="3" sm="6" xs="12">
                    <label>{{$parent.lang.chose_from_list}} {{$parent.lang.vat_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.vtype"
                        :options="invoice.vattype"
                        :label="$parent.lang.vat_type"
                        style="background:red !important"
                        @change="changePtype()"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <label>{{$parent.lang.chose_from_list}} {{$parent.lang.salesman}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.userid"
                        :options="invoice.users"
                        :label="$parent.lang.salesman"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px; color:#fff">
                                <i class="fas fa-arrow-down"></i>
                              </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.chose_from_list}} {{$parent.lang.work_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.work_type"
                        :options="workTypes"
                        :label="$parent.lang.invoice_type"
                        style="background:black !important"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="invoice.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <label>{{$parent.lang.quot_date}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="invoice.date"
                            :label="$parent.lang.quot_date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        >{{ invoice.date }}</b-form-input>
                        </template>
                        <v-date-picker
                        v-model="invoice.date"
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(invoice.date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row>
                <v-window>{{$parent.lang.quotation_details}}</v-window>
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width:120px" class="text-center backBlack" v-if="$store.state.licenseType.showCarPlate">{{$parent.lang.plate_number}}</th>
                                <th style="width:100px" class="text-center backBlack" v-if="$store.state.licenseType.showItemCode">{{$parent.lang.item_code}}</th>
                                <th style="width:35%" class="text-center backBlack">{{$parent.lang.description}}</th>
                                <th class="text-center backBlack">{{$parent.lang.qtty}}</th>
                                <th class="text-center backBlack">{{$parent.lang.item_price}}</th>
                                <th class="text-center backBlack">{{$parent.lang.discount}}</th>
                                <th class="text-center backBlack">{{$parent.lang.total}}</th>
                                <th class="text-center backBlack">{{$parent.lang.vat}}</th>
                                <th class="text-center backBlack">{{$parent.lang.ftotal}}</th>
                                <th class="text-center blackBack">{{$parent.lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td v-if="$store.state.licenseType.showCarPlate">{{ item.plate_number }}</td>
                                <td>
                                    <div v-if="!$store.state.licenseType.canEditQuot">{{ item.itemcode }}</div>
                                    <div v-else>
                                        <b-form-input class="inborder" v-model="tbrows[index].itemcode" @change="getItem(index)" />
                                    </div>
                                </td>
                                <td v-if="$store.state.licenseType.showItemCode">
                                    
                                    <div v-if="!$store.state.licenseType.canEditQuot">
                                        {{ item.item_name }}
                                        <div>
                                            <small>{{ item.description }}</small>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <b-form-input class="inborder" :list="`my-list-${index}`" v-model="tbrows[index].item_name" @change="changeMe(index)" />
                                            <datalist :id="`my-list-${index}`">
                                                <option v-for="(item,index) in itemsworkdes" :key="index">{{item.item_code}} -||- {{ item[`item_name${$parent.lang.langname}`] }}</option>
                                            </datalist>    
                                        </div>
                                        <div><b-form-textarea class="inborder" v-model="tbrows[index].description" @change="changeUpdate(index)" /></div>
                                    </div>
                                </td>
                                <td v-if="!$store.state.licenseType.canEditQuot">{{ item.qty }}</td>
                                <td v-else><b-form-input class="inborder" v-model="tbrows[index].qty" @change="changeUpdate(index)" /></td>
                                <td v-if="!$store.state.licenseType.canEditQuot">{{ item.item_price }}</td>
                                <td v-else><b-form-input class="inborder" v-model="tbrows[index].item_price" @change="changeUpdate(index)" /></td>
                                <td v-if="!$store.state.licenseType.canEditQuot">{{ item.discount }}</td>
                                <td v-else>
                                    <b-form-input v-if="$store.state.licenseType.quotDisPer" class="inborder" v-model="tbrows[index].perDisc" @change="changeUpdate(index)" />
                                    <b-form-input v-else class="inborder" v-model="tbrows[index].discount" @change="changeUpdate(index)" />
                                </td>
                                <td>{{ item.total }}</td>
                                <td>{{ item.vat }}</td>
                                <td>{{ item.ftotal }}</td>
                                <td style="border-bottom: 1px solid #fff">
                                    <b-button  v-if="id == 0" type="button" variant="danger" style="background:red !important;color:#fff !important; width:65px;border:none !important;background:red" @click="removeItem(index)">{{$parent.lang.delete}}</b-button>
                                </td>
                            </tr>
                            <tr v-if="id == 0">
                                <td v-if="$store.state.licenseType.showCarPlate">
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        :options="currentCars"
                                        v-model="newrow.plate_number">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td v-if="$store.state.licenseType.showItemCode">
                                    <b-form-input class="inborder" v-model="newrow.itemcode" @change="getItem(-1)" />
                                </td>
                                <td> 
                                    <b-form-input v-model="newrow.item_name" list="my-list-id" @change="changeMe(-1)"></b-form-input>
                                    <datalist id="my-list-id">
                                        <option v-for="(item,index) in itemsworkdes" :key="index">{{item.item_code}} -||- {{ item[`item_name${$parent.lang.langname}`] }}</option>
                                    </datalist>
                                    
                                    <div>
                                        <b-form-textarea class="inborder" v-model="newrow.description" />
                                    </div>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.qty"
                                        @change="calcKit()"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.item_price"
                                        @change="calcKit()"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.discount"
                                        @change="calcKit()"
                                        v-if="!$store.state.licenseType.quotDisPer"
                                    ></b-form-input>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.perDisc"
                                        @change="calcKit()"
                                        v-else
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.total"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.vat"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.ftotal"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td style="border-bottom: 1px solid #fff">
                                    <b-button type="button" variant="success" style="background:blue !important;color:#fff !important; background:green !important;color:#fff;border:none !important;" @click="addNewRow()">{{$parent.lang.add}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty">  {{$parent.lang.discount}} </th>
                                <th class="text-center ">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                    />
                                </th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                            </tr> -->
                            <tr>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backBlack">{{$parent.lang.totals}}</th>
                                <th class="text-center backGreen">{{ full_total }}</th>
                                <th class="text-center backRed">{{ full_vat }}</th>
                                <th class="text-center backBlack">{{ full_ftotal }}</th>
                                <th class="text-center backEmpty"></th>
                            </tr>
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <label>{{ $parent.lang.notes }}</label>
                    <b-form-textarea v-model="invoice.notes" class="inborder"></b-form-textarea>
                </v-col>
                <v-col class="text-center" cols="12" md="6" sm="12" xs="12" v-if="$store.state.licenseType.showSketch">
                    <div class="flexMe">
                        <div class="text-center" style="width:100px;margin:auto">
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:blue !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.reset()">{{$parent.lang.reset}}</v-btn>
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:red !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.undo()">{{$parent.lang.undo}}</v-btn>
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:green !important;border:1px solid #ccc !important;" @click="save()">{{$parent.lang.save}}</v-btn>
                        </div>
                        <div>
                            <div style="width:400px;margin:auto">
                                <b-input-group>
                                    <b-form-select class="selborder" :options="AcurrentCars" v-model="selectedCar" />
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                </b-input-group>
                            </div>
                            <addCarScetch ref="firstCar" :CarsList="AcurrentCars" :carFirst="cars[0].plate_number" />
                        </div>
                        
                    </div>
                </v-col>
            </v-row>
            <div class="flexDis" style="margin-top:15px;">
                <div style="border:1px solid #000;width:33%;margin-inline-end:3px;" v-for="(item,index) in images" :key="index">
                    <div>
                        <img style="width:100%;" :src="item.image" />
                    </div>
                    <div style="border:1px solid #000;background:#000;color:#fff" class="text-center">{{ item.plate }}</div>
                </div>
            </div>
        </div>
    </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="addQuotation()" variant="success" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
        </div>
      </template>
        </b-sidebar>
        </v-form>
      </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
import addCarScetch from '@/components/addCarScetchQ.vue'
export default{
    components: {addCarScetch},
    data() {
        return {
            branch: '',
            checkUpId: 0,
            contact_id: 0,
            id: 0,
            attached: [],
            showClientTypeRow: true,
            payby: 1,
            itemsworkdes:[

            ],
            modal: false,
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            showcamp: true,
            showGov: false,
            cars: [{
                c1: '',
                c2: '',
                c3: '',
                text: '',
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                brand: '',
                engine_meter: '',
            }],
            invoice:{
                customer_name: '',
                customer_afforded: '',
                consumption_fees: '',
                consumption_rate: 2,
                work_type: 1,
                bid_number: '',
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1,
                discount:0,
                vtype:3,
                vattype: [
                    {
                        text: this.$parent.lang.prices_include_vat,
                        value: 3,
                    },
                    {
                        text: this.$parent.lang.prices_exclusive_vat,
                        value: 2,
                    },
                    {
                        text: this.$parent.lang.prices_without_vat,
                        value: 1,
                    },
                ],
                paytype: 1,
                paytypes: [
                    {text: 'الدفع نقدا', value: 1},
                    {text: 'الشبكة', value: 2},
                    {text: 'دفع متعدد', value: 3},
                ],
                userid: 1,
                users: [

                ],
                notes: '',
            },
            toUpdate: [],
            car: {
                c1: '',
                c2: '',
                c3: '',
                text: '',
                model: '',
                plate_number: '',
                color: '',
                made_year: '',
                brand: '',
                engine_meter: '',
            },
            fieldRules: [
                v => !!v || this.$parent.lang.required_field,
            ],
            tbrows:[
            ],
            newrow:{
                perDisc: 0,
                id:0,
                itemcode: '',
                description: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0,
                plate_number: '',
                discount: 0,
            },
            crmid: 0,
            company: {
                fullname: '',
                vatid: '',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                po_date: '',
                po_number: '',
            },
            customer:{
                id:0,
                mobile: '',
                full_name: '',
                cctype: 1,
                ctype: [
                    {
                        text: this.$parent.lang.individual_client,
                        value: 1,
                    },
                    {
                        text: this.$parent.lang.company_client,
                        value: 2,
                    }
                ]
            },
            cctype: 1,
            selectedCar: 0,
            images: [],
            calenderTypes: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        consumptionRate: function(){
            return [
                {text: 'ريال', value: 1},
                {text: '%', value: 2}
            ];
        },
        workTypes: function(){
            let t = [];

            for(let i=0;i<this.calenderTypes.length;i++){
                t.push({
                    text: this.calenderTypes[i][`name_${this.$parent.lang.langname}`],value: this.calenderTypes[i].value
                })
            }
            // return [
            //     {text: 'سمكرة ودهان', value: 1},
            //     {text: 'دهان ', value: 2},
            //     {text: 'ميكانيكا وكهرباء', value: 3},
            //     {text: 'ميكانيكا', value: 4},
            //     {text: 'كهرباء', value: 5},
            //     {text: 'شفط وتعديل', value: 6},
            // ]
            return t;
        },
        currentCars: function(){
            let t = []
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number != '')
                {
                    t.push({
                        text: this.cars[i].plate_number,
                        value: this.cars[i].plate_number,
                    })
                }
            }
            return t;
        },

        AcurrentCars: function(){
            let t = [{text: this.$parent.lang.chose_car, value: 0}]
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number != '')
                {
                    t.push({
                        text: this.cars[i].plate_number,
                        value: this.cars[i].plate_number,
                    })
                }
            }
            return t;
        },
        
    },
    created() {
        this.getInvSettings();
        this.getWorkType();
        // document.getElementById('cCom').click()
    },
    methods: {
        getCheckup(){
            if(this.checkUpId == 0 || this.checkUpId == ''){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getCurrentCheckup");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.checkUpId);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const respo = response.data.results.data;
                if(response.data.results.data.length == 0){
                    return false;
                }
                this.customer.mobile = response.data.results.data[0].mobile;
                this.getName(1);
                this.cars[0].plate_numebr = response.data.results.data[0].plate_number;
                this.company.po_number = response.data.results.data[0].po_number
                
                
                //this.getCar(response.data.results.data[0].plate_number,0);
                this.cars[0].plate_number = respo[0].plate_number
                this.cars[0].engine_meter = respo[0].engine_meter
                this.cars[0].vin = respo[0].vin
                this.cars[0].model = respo[0].model
                this.cars[0].brand = respo[0].brand
                this.cars[0].color = respo[0].color
                this.cars[0].made_year = respo[0].made_year
                const pl = this.splitPlate(respo[0].plate_number)
                this.cars[0].c1 = pl[0]
                this.cars[0].c2 = pl[1]
                this.cars[0].c3 = pl[2]
                this.cars[0].text = pl[3]
                this.invoice.customer_name = response.data.results.data[0].customer_name

                if(response.data.results.data[0].maints.length > 0){
                    for(let i=0;i<response.data.results.data[0].maints.length;i++){
                        this.tbrows.push({
                            perDisc: 0,
                            id: 0,
                            empname: '',
                            itemcode: '',
                            description: '',
                            item_name: response.data.results.data[0].maints[i].description,
                            qty: 1,
                            item_price: 0,
                            total: 0,
                            vat: 0,
                            ftotal: 0,
                            discount: 0,
                            plate_number: response.data.results.data[0].plate_number,
                            workers_name: [],
                            brand: ''
                        })
                    }   
                }

            })
        },
        splitPlate(plate)
        {
            return plate.split(' ');
        },
        changeUpdate(index){
            const data = this.tbrows[index];
            const vt = this.invoice.vtype;
            if(data.perDisc == '') data.perDisc = 0;
            if(data.perDisc > 100) data.perDisc = 100;

            let i_dis = 0;
            let to_dis = 0;
            if(this.$store.state.licenseType.quotDisPer){
                i_dis = parseFloat(data.item_price) * parseFloat(data.perDisc) /100
                to_dis = parseFloat(i_dis) * parseFloat(data.qty)
            }

            if(this.$store.state.licenseType.rowDiscountInTotal){
                if(this.$store.state.licenseType.quotDisPer){
                    data.discount = to_dis;
                }
                const price = parseFloat(data.item_price) - parseFloat(data.discount);
                data.total =  (parseFloat(data.qty)*parseFloat(price)).toFixed(2);
            }else{
                if(this.$store.state.licenseType.quotDisPer){
                    data.discount = i_dis;
                }
                const price = parseFloat(data.item_price) * parseFloat(data.qty) - parseFloat(data.discount);
                data.total =  price;
            }
            
            const vat = this.$calcVat(data.total,vt);
            // alert(vat.tot)
            data.total = this.$RoundNum(vat.tot);
            data.ftotal = this.$RoundNum(vat.ftot);
            data.vat = this.$RoundNum(vat.vat);
            this.tbrows[index].total = data.total;
            this.tbrows[index].ftotal = data.ftotal;
            this.tbrows[index].vat = data.vat;
            this.toUpdate.push(this.tbrows[index])
            this.changePtype();
            this.calcAll()
        },
        async getWorkType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getStaticOptions')
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[phrase]','calendarTypes')
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            );
            if(response && response.data){
                this.calenderTypes = response.data.results.data
            }
        },
        changeMe(index){
            if(index == -1){
                const itemn = this.newrow.item_name.split(" -||- ");
                if(itemn.length > 1){
                    this.newrow.itemcode = itemn[0];
                    this.getItem(-1);
                }
            }else{
                const itemn = this.tbrows[index].item_name.split(" -||- ");
                if(itemn.length > 1){
                    this.tbrows[index].itemcode = itemn[0];
                    this.getItem(index);
                }
                this.changeUpdate(index)
            }
            
        },
        getItem(index){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            if(index == -1){
                post.append('data[itemcode]',this.newrow.itemcode)
            }else{
                post.append('data[itemcode]',this.tbrows[index].itemcode)
            }
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                if(resposne.data.results.data.length != 0){
                    if(resposne.data.results.data[0].item_code == '' && index != -1) {
                        resposne.data.results.data[0].item_code = this.tbrows[index].itemcode
                    }else if(resposne.data.results.data[0].item_code == '' && index == -1){
                        resposne.data.results.data[0].item_code = this.newrow.itemcode
                    }
                    if(index == -1){
                        this.newrow = {
                            perDisc: 0,
                            id: 0,
                            itemcode: resposne.data.results.data[0].item_code,
                            description: resposne.data.results.data[0].description,
                            item_name: resposne.data.results.data[0][`item_name${this.lang.langname}`],
                            qty: 1,
                            item_price: resposne.data.results.data[0].default_price,
                            total: 0,
                            vat: 0,
                            ftotal: 0,
                            plate_number: '',
                            discount: 0
                        }
                        this.calcKit()
                    }else{
                        if(resposne.data.results.data.length > 0){
                            // alert(resposne.data.results.data[0].item_code);
                            this.tbrows[index] = {
                                id: this.tbrows[index].id,
                                perDisc: this.tbrows[index].perDisc,
                                itemcode: resposne.data.results.data[0].item_code,
                                description: resposne.data.results.data[0].description,
                                item_name: resposne.data.results.data[0][`item_name${this.lang.langname}`],
                                qty: 1,
                                item_price: resposne.data.results.data[0].default_price,
                                total: 0,
                                vat: 0,
                                ftotal: 0,
                                plate_number: '',
                                discount: 0
                            }
                            this.changePtype();
                        }
                    }
                    
                    
                }
            });
        },
        save(){
            if(this.$refs.firstCar.image == ''){
                let message = this.$parent.lang.plase_select_car_scratch;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.selectedCar == '' || this.selectedCar == 0){
                let message = this.$parent.lang.chose_car_before_continue;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            let test = false;
            for(let i =0;i < this.images.length;i++){
                if(this.selectedCar == this.images[i].plate){
                    test = true;
                    this.images[i].image = this.$refs.firstCar.image
                }
            }
            if(!test){
                this.images.push({
                    image: this.$refs.firstCar.image,
                    plate: this.selectedCar
                })
            }
            
            this.$refs.firstCar.$refs.VueCanvasDrawing.reset()
            this.$refs.firstCar.image = '';
            this.selectedCar = 0;
        },
        reset(){
            this.id = 0
            this.cars = [{
                c1: '',
                c2: '',
                c3: '',
                text: '',
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                brand: '',
            }]
            this.customer = {
                id:0,
                mobile: '',
                full_name: '',
                cctype: 1,
                ctype: [
                    {
                        text: this.$parent.lang.individual_client,
                        value: 1,
                    },
                    {
                        text: this.$parent.lang.company_client,
                        value: 2,
                    }
                ]
            }
            this.newrow = {
                perDisc: 0,
                id: 0,
                itemcode: '',
                description: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0,
                plate_number: '',
                discount: 0
            }
            this.tbrows = []
        },
        changeCusType(id){
            if(id == 0){
                this.customer.cctype = 1;
                this.showcamp = false;
                this.showGov = false;
                document.getElementById('indiv').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('blackBack');
                document.getElementById('cCom').classList.remove('blackBack');
                document.getElementById('cGov').classList.remove('blackBack');

                document.getElementById('cInd').classList.add('blackBack');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customer.cctype = 2;
                this.showcamp = true;
                this.showGov = false;
                document.getElementById('comps').classList.remove('hideme');
                document.getElementById('indiv').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('blackBack');
                document.getElementById('cCom').classList.remove('blackBack');
                document.getElementById('cGov').classList.remove('blackBack');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('blackBack');
                document.getElementById('cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customer.cctype = 3;
                this.showGov = true;
                this.showcamp = false;
                document.getElementById('goves').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('indiv').classList.add('hideme');

                document.getElementById('cInd').classList.remove('blackBack');
                document.getElementById('cCom').classList.remove('blackBack');
                document.getElementById('cGov').classList.remove('blackBack');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('blackBack');

            }
        },
        getInfo(){
            
            if(this.custom == null) {return false;}
            this.customer.mobile = this.custom.mobile;
            this.company.fullname = this.custom.company;
            this.company.vatid = this.custom.vatid;
            this.company.crt_number = this.custom.crt_number;
            this.getName();
        },
        addQuotation(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.mobile = '1001'
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = 'عميل نقدي'
            }
            if(this.tbrows.length == 0){
                let message = this.$parent.lang.can_not_add_empty_row;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
           if(this.customer.cctype == 2 && this.company.fullname == ''){
               let message = this.$parent.lang.please_add_company_info;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }

            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            if(this.id == 0) {
                post.append("type" , "addQuotation");
            }
            else{
                post.append("type" , "updateQuotation");
            }
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(let i=0;i<this.images.length;i++){
                post.append('data[images]['+i+'][image]',this.images[i].image)
                post.append('data[images]['+i+'][plate]',this.images[i].plate)
            }
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            let discount = 0;
            for(let i = 0; i < this.tbrows.length;i++){
                discount = parseFloat(this.tbrows[i].discount) + parseFloat(discount)
                for(const [key, value] of Object.entries(this.tbrows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            
            for(let i = 0; i < this.cars.length;i++){
                for(const [key, value] of Object.entries(this.cars[i])){
                    post.append('data[cars]['+i+']['+key+']]',value);
                }
            }
            post.append("data[id]",this.id);
            post.append("data[checkUpId]",this.checkUpId);
            post.append("data[contract_id]",this.contract_id);
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",this.full_total);
            post.append("data[inovice][full_vat]",this.full_vat);
            post.append("data[inovice][full_ftotal]",this.full_ftotal);
            post.append("data[inovice][discount]",discount);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][invoice]",this.invoice.invoice);
            post.append("data[inovice][notes]",this.invoice.notes);
            post.append("data[inovice][card_type]",this.invoice.work_type);
            post.append("data[inovice][po_number]",this.company.po_number);
            post.append("data[inovice][po_date]",this.company.po_date);
            post.append("data[inovice][customer_name]",this.invoice.customer_name);
            post.append("data[inovice][customer_afforded]",this.invoice.customer_afforded);
            post.append("data[inovice][consumption_fees]",this.invoice.consumption_fees);
            post.append("data[inovice][consumption_rate]",this.invoice.consumption_rate);
            post.append("data[toUpdate]",JSON.stringify(this.toUpdate));
            post.append("data[crmid]",this.crmid);
            // post.append("data[car][model]",this.car.model);
            // post.append("data[car][plate_number]",this.car.plate_number);
            // post.append("data[car][color]",this.car.color);
            // post.append("data[car][made_year]",this.car.made_year);

            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                let message = this.$parent.lang.quotation_added;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                document.getElementById('closeDil').click();
                if(this.id == 0) {
                    this.$parent.viewQuotation.getQuotations();
                }
                if(this.showClientTypeRow) this.$parent.getQuotations(1)
                else this.$parent.getCustomerInfo()
            //    this.$router.push({path: '/view-quot/' + resposne.data.results.data})
                return false;
            })

        },
        changePtype(){
            let tbs = [];
             if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    let pr = 0;
                    let total = 0;
                    if(!this.$store.state.licenseType.rowDiscountInTotal){
                        pr = parseFloat(this.tbrows[i].item_price) - parseFloat(this.tbrows[i].discount)
                        total = parseFloat(this.tbrows[i].qty) * parseFloat(pr);
                    }else{
                        pr = (parseFloat(this.tbrows[i].item_price) * this.tbrows[i].qty) - parseFloat(this.tbrows[i].discount)
                        total = parseFloat(pr);
                    }
                    // alert(total);
                    const vs = this.$calcVat(total,this.invoice.vtype);
                    
                    const itm = {
                        id: this.tbrows[i].id,
                        perDisc: this.tbrows[i].perDisc,
                        itemcode: this.tbrows[i].itemcode,
                        description: this.tbrows[i].description,
                        item_name: this.tbrows[i].item_name,
                        qty: this.tbrows[i].qty,
                        item_price: this.tbrows[i].item_price,
                        discount: this.tbrows[i].discount,
                        total: vs.tot,
                        vat: vs.vat,
                        ftotal: vs.ftot,
                        plate_number: this.tbrows[i].plate_number,
                        // discount_t: this.tbrows[i].discount_t
                    }
                    
       
                    this.tbrows[i].total = vs.tot,
                    this.tbrows[i].vat = vs.vat,
                    this.tbrows[i].ftotal = vs.ftot
                    tbs.push(itm);
                    // this.changeUpdate(i);

                }
            }
            this.tbrows = tbs;
            tbs = [];
            const prs = parseFloat(this.newrow.item_price) - parseFloat(this.newrow.discount)
            const total = parseFloat(this.newrow.qty) * parseFloat(prs);
            const vs = this.$calcVat(total,this.invoice.vtype);
            this.newrow = {
                perDisc: 0,
                id: 0,
                itemcode: this.newrow.itemcode,
                description: this.newrow.description,
                item_name: this.newrow.item_name,
                qty: this.newrow.qty,
                item_price: this.newrow.item_price,
                total: vs.tot,
                vat: vs.vat,
                ftotal: vs.ftot,
                discount: this.newrow.discount
            }
            this.calcAll();
        },
        getQuotation(id){
            this.id = id;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', "getQuotations");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[id]',id)
            post.append('data[status]',0)
            // // console.log(this.quot);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                this.cars = res.results.data[0].cars
                for(let i=0;i<this.cars.length;i++){
                    this.cars[i].text = this.cars[i].nums
                }
                this.customer.mobile = res.results.data[0].mobile
                this.getName();
                this.tbrows = res.results.data[0].rows
                this.invoice.notes = res.results.data[0].notes;
                this.calcAll();
            })
        },
        deleteCar(index){
            this.cars.splice(index,1);
        },
        addNewCar(){
            this.cars.push({
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
            });
        },
        calcAll(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    this.full_total = parseFloat(this.full_total) + parseFloat(this.tbrows[i].total);
                    this.full_vat = parseFloat(this.full_vat) + parseFloat(this.tbrows[i].vat);
                    this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.tbrows[i].ftotal);
                }
            }
            
            if(this.invoice.discount > 30 && this.$store.state.licenseType.perDiscount){
                this.invoice.discount = 30;
            }
            if(this.invoice.discount != 0 && !this.$store.state.licenseType.perDiscount){
                if(this.full_total < this.invoice.discount){
                    this.invoice.discount = this.full_total
                }
                this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.discount);
                let vatper = 0.15
                if(this.invoice.vtype == 1){
                    vatper = 0
                }
                this.full_vat = parseFloat(this.full_total) * vatper;
                this.full_ftotal = parseFloat(this.full_total) + this.full_vat ;
            }else if(this.invoice.discount != 0 && this.$store.state.licenseType.perDiscount){
                this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_vat = parseFloat(this.full_vat) - parseFloat(this.invoice.discount) * parseFloat(this.full_vat) / parseFloat(100);
                this.full_ftotal = parseFloat(this.full_ftotal) - parseFloat(this.invoice.discount) * parseFloat(this.full_ftotal) / parseFloat(100);
            }

            // if(this.invoice.customer_afforded != ''){
                
            //     this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.customer_afforded);
            //     let vatper = 0.15
            //     if(this.invoice.vtype == 1){
            //         vatper = 0
            //     }
            //     this.full_vat = parseFloat(this.full_total) * vatper;
            //     this.full_ftotal = parseFloat(this.full_total) + this.full_vat ;
            // }
            // if(this.invoice.consumption_fees != ''){
            //     let consumption = this.invoice.consumption_fees;
            //     if(this.invoice.consumption_rate == 2){
            //         consumption = this.$RoundNums(parseFloat(consumption)/100,2);
            //         consumption = this.$RoundNums(parseFloat(this.full_total) * parseFloat(consumption),2);
            //     }
            //     this.full_total = parseFloat(this.full_total) - parseFloat(consumption);
            //     let vatper = 0.15
            //     if(this.invoice.vtype == 1){
            //         vatper = 0
            //     }
            //     this.full_vat = parseFloat(this.full_total) * vatper;
            //     this.full_ftotal = parseFloat(this.full_total) + this.full_vat ;
            // }

            this.full_total = this.$RoundNum(this.full_total);
            this.full_vat = this.$RoundNum(this.full_vat);
            this.full_ftotal = this.$RoundNum(this.full_ftotal);
            // if(this.invoice.paytype == 1 || this.invoice.paytype == 3){
            //     this.invoice.payment.cash = this.full_ftotal;
            //     this.invoice.payment.span = 0;
            // }else if(this.invoice.paytype == 2){
            //     this.invoice.payment.cash = 0;
            //     this.invoice.payment.span = this.full_ftotal;
            // }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            
            if(this.newrow.discount == '') this.newrow.discount = 0
            if(this.newrow.perDisc == '' || isNaN(this.newrow.perDisc)) this.newrow.perDisc = 0
            let i_dis = 0;
            let to_dis = 0;
            if(this.$store.state.licenseType.quotDisPer){
                i_dis = parseFloat(this.newrow.item_price) * parseFloat(this.newrow.perDisc) /100
                to_dis = parseFloat(i_dis) * parseFloat(this.newrow.qty)
            }
            
            const vt = this.invoice.vtype;
            let price = 0;
            if(!this.$store.state.licenseType.rowDiscountInTotal){
                if(this.$store.state.licenseType.quotDisPer){
                    this.newrow.discount = i_dis;
                }
                const pr = parseFloat(this.newrow.item_price) - parseFloat(this.newrow.discount)
                 price = +pr * +this.newrow.qty;
                 
            }else{
                if(this.$store.state.licenseType.quotDisPer){
                    this.newrow.discount = to_dis;
                }
                const pr = parseFloat(this.newrow.item_price) * parseFloat(this.newrow.qty) - parseFloat(this.newrow.discount)
                 price = pr;
            }
            
            const vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
        },
        showSnotify()
        {
            return new Promise((resolve) => {
                this.$snotify.error("تم اضافة الصنف الى الفاتورة مسبقا هل ترغب بالمتابعة", this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.agree,
                            action: (toast) => {
                                this.$snotify.remove(toast.id);
                                resolve('agree');
                            }
                        },
                        {
                            text: this.lang.cancel,
                            action: (toast) => {
                                this.$snotify.remove(toast.id);
                                resolve('cancel');
                            }
                        },
                    ]
                });
            });
        },
        async addNewRow() {
            let item = this.newrow
            let x = 0;
            const length = this.tbrows.length;
            for (let i = 0; i < length; i++) {
                if (this.tbrows[i].item_name == item.item_name) {
                    const result = await this.showSnotify();
                    if (result === 'cancel') {
                        x = 1;
                        break;
                    }
                }
            }
            if(x == 1) return false;
            if(this.newrow.item_name == ''){
                let message = this.$parent.lang.can_not_add_row_without_discription;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.newrow.qty == '' || this.newrow.qty == 0){
                let message = this.$parent.lang.can_not_add_row_with_qtty;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            
            this.tbrows.push(this.newrow);
            this.newrow = {
                perDisc: 0,
                id: 0,
                itemcode: '',
                description: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0,
                plate_number: this.newrow.plate_number,
                discount: 0
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.itemsworkdes = [];
                            this.allProducts = res.results.data;
                            
                            
                            // for(let i= 0; i < res.results.data.products.length; i++){
                            //    this.itemsworkdes.push(res.results.products[i].name);
                            // }
                        }
                        this.itemsworkdes = res.results.data.allproduct;
                        console.log(this.itemsworkdes)
                    }
                }
            )
        },
        getName(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.crt_number = res.results.data['crt_number'];
                        this.company.cctype = res.results.data['customer_type'];
                        this.changeCusType(parseInt(res.results.data['customer_type']) - 1)
                    }
                }
            )
        },
        plNum(index){
            if(this.cars[index].c1 != '' && this.cars[index].c2  != '' && this.cars[index].c3  !='' && this.cars[index].text){
                this.cars[index].plate_number = this.cars[index].c1 + " " + this.cars[index].c2 + " " + this.cars[index].c3 + " " + this.cars[index].text;
                this.getCar(this.cars[index].plate_number,index)
            }
        },
        getCar(plate_number,index){
            // // console.log("index",index)
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','carInfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[plate_number]',plate_number);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    let cars = [];
                    if(res.error.number == 200){
                        let car = {
                            c1: res.results.data.c1,
                            c2: res.results.data.c2,
                            c3: res.results.data.c3,
                            text: res.results.data.text,
                            plate_number:res.results.data.plate_number,
                            made_year:res.results.data.made_year,
                            model:res.results.data.model,
                            vin:res.results.data.vin,
                            id:res.results.data.id,
                            color:res.results.data.color,
                        }
                        this.newrow.plate_number = res.results.data.plate_number;
                        // this.updateCars(car);
                        this.cars[index] = car;
                        
                        this.addNewCar();
                        this.cars.pop()
                    }else{
                        this.newrow.plate_number = plate_number;
                    }
                }
            )
        }
    },
}
</script>
<style>
h6{
  font-weight: bolder;
}
.v-input--selection-controls {
    margin-top: 2px !important;
    padding-top: 2px !important;
}
.tobbuttom{
  display:flex;
  flex-direction: row;
  justify-content:center;
  justify-items: center;
  align-items: center;
}
.borderRad_left{
  border-top-left-radius: 5px 5px !important;
  border-bottom-left-radius: 5px 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-size: .8rem  !important;
  margin-inline-start: 5px !important;
}
.borderRad_right{
  border-top-left-radius: 0 0 !important;
  border-bottom-left-radius: 0 0 !important;
  border-top-right-radius: 5px 5px !important;
  border-bottom-right-radius: 5px 5px !important;
  font-size: .8rem  !important;
  margin-inline-end: 5px  !important;
}
.borderRad_none{
  border-top-left-radius: 0 0 !important;
  border-bottom-left-radius: 0 0 !important;
  border-top-right-radius: 0 0 !important;
  border-bottom-right-radius: 0 0 !important;
  font-size: .8rem  !important;
}
.hideme{
  display:none !important;
}
.dispFlex{
  display:flex;
  justify-content: center !important;
}
.weightColor{
  background:#fff !important;
  color:#000 !important;
}
.redColor{
  background:red !important;
  color:#fff !important;
}
.borderRad_right,  .borderRad_left, .borderRad_none{
    padding:2px 5px !important;
}
.blackBack{
    background:#000 !important;
    color:#fff !important;
}
</style>