<template>
    <div>
        <header-portrate v-if="!is_mobile" />
        <exports-print ref="exportbar" v-if="!is_mobile" />
        <div v-if="is_mobile" style="padding:5px;background:#d7b100">
            <div class="text-center p-2" style="background:red;color:#fff;margin:auto 50px;" @click="$router.push({path: '/logout'})">
                {{ lang.logout }}
            </div>
        </div>
        <div>
            <TabsComp :tabs="tabs" v-if="!is_mobile" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
                <v-row class="mt-10">
                    <v-col
                        cols="12"
                        md="12"
                        sm="12">
                        <form autocomplete="off">
                            <v-row>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.start_date}}</label>
                                    <b-input-group>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.end_date}}</label>
                                    <b-input-group>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList"/>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.mobile}}</label>
                                    <b-form-input class="inborder text-center" v-model="mobile" />
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.customer_name}}</label>
                                    <b-form-input class="inborder text-center" v-model="customer_name" />
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label></label>
                                    <b-input-group style="margin-top:-5px">
                                        <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getCustomers()">{{lang.search}}</b-button>
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12" class="text-end">
                                    <b-button
                                        v-b-toggle.addHomeSales
                                        class="btn-sm btn btn-success"
                                        @click="addCcus()"
                                        style="
                                            margin-top: 25px;
                                            width: 150px;
                                            background: transparent;
                                            border: none;
                                            color: #000;"
                                            id="multiply-button"
                                        >
                                        <div style="width: 150px" :class="`multiply-button-content-` + lang.lalgin">
                                            {{ lang.add_customer }}
                                        </div>
                                    </b-button>
                                </v-col>
                            </v-row>
                        </form>
                    </v-col>
                    
                    <v-col cols="12" :style="`direction: `+lang.dir+`;`">
                        <div  v-if="is_mobile" style="display:none;">
                            <v-card v-for="(item,index) in tablerows" :style="`margin:5px;border:1px solid #000`" :key="index">
                                <v-card-title style="font-size:18px;color:#000 !important;">{{ item.customer_name }}</v-card-title>
                                <v-card-subtitle style="color:#000 !important;">{{ item.last_update }}</v-card-subtitle>
                                <v-card-text :style="`background:${item.next_action_bg} !important;color:${item.next_action_co} !important`">
                                    <div style="color:#000 !important;">{{lang.next_action}} : {{ item[`next_action_`+lang.langname] }}</div>
                                    <div style="color:#000 !important;">{{lang.next_action_date}} : {{ item.next_action_date }}</div>
                                </v-card-text>
                                <v-avatar rounded="0" :style="`width:100px;position:absolute;top:10px; left: 10px;background:${item.status_bg} !important;color:${item.status_co} !important`">
                                    {{ item[`status_`+lang.langname] }}
                                </v-avatar>
                                <v-card-actions class="text-center">
                                    <v-btn style="margin:auto;width:100px;background:blue;color:#fff !important" @click="openView(item.id)" v-b-toggle.view_crm>{{ lang.view }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </div>
                        <v-row>
                            <v-col cols="12" md="12" style="">
                                <div style="display:none;">
                                    <v-simple-table style="display:none;">
                                        <thead>
                                            <tr>
                                                <th style="text-align:center;" colspan="2" v-for="(item,index) in inStatus" :key="index">
                                                    {{ item[`name_`+lang.langname] }}
                                                </th>
                                                <th style="text-align:center;" colspan="2" >
                                                    الدعم الفني
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style="text-align:center;" v-for="i in inStatus.length*2" :key="i" class="backBlack">
                                                    <span v-if="i%2==0">المبلغ</span>
                                                    <span v-else>العدد</span>
                                                </th>
                                                <th style="text-align:center;" class="backBlack">المبلغ</th>
                                                <th style="text-align:center;" class="backBlack">العدد</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center;cursor:pointer;" v-for="(item,index) in statuss" colspan="2" :key="index" @click="getStatus(inStatus[index])">
                                                    <div style="display:flex;justify-content:space-between">
                                                        <div style="text-align:center;width:50%;border-inline-end:1px solid #000">{{ parseFloat(item.counts) }}</div>
                                                        <div style="text-align:center;width:50%">{{ parseFloat(item.total) }}</div>
                                                    </div>
                                                </td>
                                                <td style="text-align:center;">{{ parseFloat(support.total) }}</td>
                                                <td style="text-align:center;">{{ support.counts }}</td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                    <br>
                                </div>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="backBlack text-center" style="width:70px;">#</th>
                                            <th class="backBlack text-center">{{lang.product}}</th>
                                            <th class="backBlack text-center">تاريخ الاتصال</th>
                                            <th class="backBlack text-center">{{lang.time}}</th>
                                            <th class="backBlack text-center">{{lang.expected_income}}</th>
                                            <th class="backBlack text-center">محول بواسطة</th>
                                            <th class="backBlack text-center">{{lang.agent}}</th>
                                            <th class="backBlack text-center">{{lang.source}}</th>
                                            <th class="backBlack text-center">{{lang.full_name}}</th>
                                            <th class="backBlack text-center">{{lang.mobile}}</th>
                                            <th class="backBlack text-center">{{lang.city}}</th>
                                            <th class="backBlack text-center">{{lang.industrial}}</th>
                                            <th class="backBlack text-center">تاريخ الاضافة</th>
                                            <!-- <th class="text-center backBlack" 
                                                v-for="(item,index) in supportTypes" 
                                                :key="index"
                                                style="width:80px"
                                            > 
                                            {{ item[`name_${lang.langname}`] }}</th>
                                            <th class="backBlack text-center">{{lang.status}}</th>-->
                                            <th class="backBlack text-center">{{lang.action}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in tablerows1" :key="index">
                                            <td class="text-center">{{ item.counter }}</td>
                                            <td class="text-center" :style="`${item.service_.style}`">{{ item.service_[`name_${lang.langname}`] }}</td>
                                            <td class="text-center">{{ item.executed_date }}</td>
                                            <td class="text-center">{{ item.executed_time }}</td>
                                            <td class="text-center">{{ parseFloat(item.total_income) }}</td>
                                            <td class="text-center">{{ item.trans_from }}</td>
                                            <td class="text-center">{{ item.agent }}</td>
                                            <td class="text-center" :style="`${item.source.style}`">{{ item.source[`name_${lang.langname}`] }}</td>
                                            <td class="text-center">{{ item.full_name }}</td>
                                            <td class="text-center">{{ item.mobile }}</td>
                                            <td class="text-center">{{ item.city }}</td>
                                            <td class="text-center">{{ item.industrial }}</td>
                                            <td class="text-center">{{ item.created_date }}</td>
                                            <!-- <td class="text-center" v-for="(itm,ind) in supportTypes" :key="ind" :style="`${item[`procedure_${itm.value}`].style}`">{{ item[`procedure_${itm.value}`][`name_${lang.langname}`] }}</td>

                                            <td class="text-center" :style="`${item.service_status.style}`">{{ item.service_status[`name_${lang.langname}`] }}</td> -->
                                            <td class="text-center backBlue" style="cursor:pointer" @click="showMe(item)" v-b-toggle.customer_crm>{{ lang.action }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="backEmpty"></th>
                                            <th class="backEmpty"></th>
                                            <th class="backEmpty"></th>
                                            <th class="backEmpty"></th>
                                            <th class="backGreen text-center">{{ totals.income }}</th>
                                            <th class="backEmpty" colspan="5"></th>
                                            
                                            <th class="backEmpty"></th>
                                            <th class="backEmpty"></th>
                                            <th class="backEmpty"></th>
                                        </tr>
                                    </tfoot>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        
                    </v-col>

                </v-row>
            </v-row>
        </div>
        <b-form-input style="display:none;" v-model="thisPath" @change="getCustomers()" />
        <addHomeSales ref="customerRef" />
        <customerCRM ref="customerCRM" />
        
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import ExportsPrint from '@/components/exports-print.vue'
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
import addHomeSales from '@/components/addHomeSales.vue'
import customerCRM from '@/components/customerCRM.vue'


export default{
    name: 'NewCRM',
    components: {
        HeaderPortrate,
        Footer,
        ExportsPrint
        ,TabsComp,
        addHomeSales,
        customerCRM
    },
    data() {
        return {
            customer_name: '',
            cStatus: '',
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear() - 1,
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            tablerows: [],
            search: '',
            page: 1,
            pageCount:0,
            staticOption: [],
            // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date: '',
            mobile: '',
            industrial: 1,
            cpage:0,
            // inStatus: [],
            statuss: [],
            support: {total: 0,counts:0},
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tablerows1: function(){
            let t = []
            // if(this.mobile != ''){
            //     t = this.tablerows.find((element) => element.includes(mobile));
            // }
            if(this.mobile == ''){
                return this.tablerows;
        }
            for(var i=0; i<this.tablerows.length; i++) {
                Object.keys(this.tablerows[i]).forEach(key => {
                    if(this.tablerows[i][key] === null){
                        ///
                    }else{
                        if(String(this.tablerows[i][key]).includes(this.mobile)){
                            t.push(this.tablerows[i]);
                        }
                        
                    }
                })
            }
            return t;
        },
        totals: function(){
            // console.log("table row is",this.tablerows);
            let t = {
                income: 0,
            }
            for(let i=0;i<this.tablerows.length;i++){
                t.income = parseFloat(t.income) + parseFloat(this.tablerows[i].total_income)
            }

            return t;

        },
        expected_total: function(){
            let t = 0;
            // console.log("showarround",this.tablerows);
            for(let i = 0;i<this.tablerows.length;i++){
                //t = parseFloat(t) + parseFloat(this.tablerows[i].expected_amount)
            }
            return this.$convertToComa(this.$RoundNums(t,2),this.$store.state.licenseType.moneyComma);
        },
        is_mobile: function(){
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        headers: function(){
            let t = [
                {
                    text: this.lang.customer_type,
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: !this.$store.state.licenseType.isBill ? '' : "display:none;"
                },
                {
                    text: this.lang.city,
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: this.lang.mobile,
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: this.lang.customer_name,
                    align: 'center',
                    filterable: false,
                    value: 'customer_name',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: 'نوع الدعم',
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: this.lang.erocedure_date,
                    align: 'center',
                    filterable: false,
                    value: 'procedure',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: this.lang.procedure,
                    align: 'center',
                    filterable: false,
                    value: 'procedure',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: this.lang.client_priority,
                    align: 'center',
                    filterable: false,
                    value: 'procedure',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: this.lang.agent,
                    align: 'center',
                    filterable: false,
                    value: 'procedure',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                
                {
                    text: this.lang.expected_income,
                    align: 'center',
                    filterable: false,
                    value: 'customer_name',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: 'display:none'
                },
                {
                    text: this.lang.contracts,
                    align: 'center',
                    filterable: false,
                    value: 'contracts',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: this.$store.state.licenseType.car_contract ? '' : "display:none;"
                },
                {
                    text: this.lang.invoices,
                    align: 'center',
                    filterable: false,
                    value: 'invoices',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: !this.$store.state.licenseType.isBill ? '' : "display:none;"
                },
                {
                    text: this.lang.quotations,
                    align: 'center',
                    filterable: false,
                    value: 'quotations',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: !this.$store.state.licenseType.isBill ? '' : "display:none;"
                },
                {
                    text: this.lang.job_cards,
                    align: 'center',
                    filterable: false,
                    value: 'invoices',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: (this.$store.state.licenseType.cars && !this.$store.state.licenseType.isBill) ? '' : "display:none;"
                },
                {
                    text: this.lang.paid,
                    align: 'center',
                    filterable: false,
                    value: 'total',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: this.$store.state.licenseType.carxxs ? '' : "display:none;"
                },
                {
                    text: this.lang.remain,
                    align: 'center',
                    filterable: false,
                    value: 'remain',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: this.$store.state.licenseType.carxxs ? '' : "display:none;"
                },
                {
                    text: this.lang.status,
                    align: 'center',
                    filterable: false,
                    value: 'status',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                    value: 'agent',
                    sortable: false,
                    class: 'pa-0 backBlack text-center',
                    style: '',
                    permission: ''
                },

            ];
            return t;
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        InDustr: function(){
            let t = [{text: '', value: 0}];
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'inDustrial'){
                    t.push({text: this.staticOption[i][`name_`+this.lang.langname],value: this.staticOption[i].value})
                }
            }
            return t;
        },
        inStatus: function(){
            let t = [];
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'crmStatus'){
                    t.push(this.staticOption[i])
                }
            }
            return t;
        },
        supportTypes: function(){
            let t = [];
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'crmActions'){
                    t.push(this.staticOption[i])
                }
            }
            return t;
        },
        Statics: function(){
            let t = {
                crmActions: {},
                crmStatus: {},
                crmType: {},
            };
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'crmActions'){
                    t.crmActions[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
                else if(this.staticOption[i].option_phrase == 'crmStatus'){
                    t.crmStatus[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
                else if(this.staticOption[i].option_phrase == 'crmType'){
                    t.crmType[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
            }

            return t;
        },
        tablesRow: function(){
            
            return this.tablerows
        },
        thisPath: function() 
        {
            
            // this.getCustomers();
            return this.$route.path
        }
    },
    methods: {
        getStatus(item){
            console.log(item);
            this.cStatus = item.value;
            this.getCustomers()
        },
        showMe(item){
            this.$refs.customerCRM.customerid = item.customer_id;
            this.$refs.customerCRM.getCustomerInfo();
            this.$refs.customerCRM.getSales();
        },
        resetContr(){
            this.$refs.addContract.resetAll();
        },
        addCcus(id){
            this.$refs.customerRef.reset();
            
        },
        getCus(id){
            this.$refs.customerRef.reset();
            this.$refs.customerRef.customerid = id;
            this.$refs.customerRef.getCustomerInfo();
        },
        getCustomers(){
            const post = new FormData(); 
            post.append("type","getCustomerSales");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[id]",0)
            this.cStatus = this.$route.params.status;
            // if(this.$route.path == '/sales'){
            //     this.cStatus = 1;
            // }
            // if(this.$route.path == '/sales-demo'){
            //     this.cStatus = 2;
            // }
            // if(this.$route.path == '/sales-calls'){
            //     this.cStatus = 5;
            // }
            // if(this.$route.path == '/sales-win'){
            //     this.cStatus = 8;
            // }
            // if(this.$route.path == '/sales-quot'){
            //     this.cStatus = 3;
            // }
            // if(this.$route.path == '/sales-quot-com'){
            //     this.cStatus = 4;
            // }

            post.append("data[status]",this.cStatus)
            post.append("data[mobile]",this.mobile)
            post.append("data[customer_name]",this.customer_name)
    
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.tablerows = response.data.results.data;
                    this.statuss = response.data.results.statuss;
                    this.support = response.data.results.support;
                }
            })

        },
        openView(id){
            this.$refs.CarContractView.getContracts (id);
        },
        getall(type,id,ret){
            // return this.Statics[type][id][ret];
        },
        getNext(page){
            this.cpage = parseInt(page) - 1;
            this.getContracts();
        },
        
        getStaticOp(){
            
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            post.append('type','getStaticOptions')
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[test]','1')
            axios.post(this.$store.state.SAMCOTEC.r_path, post)
            .then((response) => {
                if(response && response.data){
                    this.staticOption = response.data.results.data;
                    this.users = response.data.results.users;
                    // this.getClients()
                }
            })

        }
        
    },
    watch: {
        '$route.params.status'(newId) {
            this.cStatus = newId;
            this.getCustomers();
        }
    },
    created(){
        this.cStatus = this.$route.params.status;
        this.getStaticOp()
        // this.getContracts()
        this.getCustomers()
    }
}
</script>